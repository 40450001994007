import axios from 'axios';
import createInfoObject from '@/lib/create-info-object';
import getBearerAuth from '@/lib/get-bearer-auth';
//ENUMS
export var serviceTypeNames;
(function (serviceTypeNames) {
    serviceTypeNames["ORDER_RETURN_UPDATE"] = "ORDER_RETURN_UPDATE";
    serviceTypeNames["ORDER_RETURN_CREATE"] = "ORDER_RETURN_CREATE";
    serviceTypeNames["VERIFY_VAT"] = "SCENARIO_SG03_X00041";
    serviceTypeNames["SHORTLIST_UPDATE"] = "SHORTLIST_UPDATE";
    serviceTypeNames["PAYMENT_METHOD_UPDATE"] = "PAYMENT_METHOD_UPDATE";
    serviceTypeNames["PAYMENT_METHOD_DELETE"] = "PAYMENT_METHOD_DELETE";
    serviceTypeNames["PAYMENT_METHOD_SET_DEFAULT"] = "PAYMENT_METHOD_SET_DEFAULT";
    serviceTypeNames["ORDER_RETURN_LIST"] = "ORDER_RETURN_LIST";
    serviceTypeNames["CREDIT_PACK_LIST"] = "CREDIT_PACK_LIST";
    serviceTypeNames["ACTIVITY_LOG_LIST"] = "ACTIVITY_LOG_LIST";
    serviceTypeNames["BILLING_LIST"] = "BILLING_LIST";
    serviceTypeNames["CAPTCHA_VERIFY"] = "SCENARIO_SG05_X00022";
    serviceTypeNames["CART_SHIPPING_CREATE"] = "SCENARIO_SG04_X00038";
    serviceTypeNames["CART_CLEAR"] = "SCENARIO_SG04_X00017";
    serviceTypeNames["CART_COUPON_ADD"] = "SCENARIO_SG04_X00019";
    serviceTypeNames["CART_COUPON_DELETE"] = "SCENARIO_SG04_X00020";
    serviceTypeNames["CART_COUPON_LIST"] = "COUPON_LIST";
    serviceTypeNames["CART_DELETE"] = "CART_DELETE";
    serviceTypeNames["CART_ITEM_ADD"] = "SCENARIO_SG04_X00018";
    serviceTypeNames["CART_ITEM_DELETE"] = "CART_ITEM_DELETE";
    serviceTypeNames["CART_ITEM_UPDATE"] = "CART_ITEM_UPDATE";
    serviceTypeNames["CART_OBTAIN"] = "SCENARIO_SG04_X00016";
    serviceTypeNames["CART_SALES_ORDER_CREATE"] = "CART_SALES_ORDER_CREATE";
    serviceTypeNames["COMMENT_CREATE"] = "COMMENT_CREATE";
    serviceTypeNames["COMMENT_DELETE"] = "COMMENT_DELETE";
    serviceTypeNames["COMMENT_LIST"] = "COMMENT_LIST";
    serviceTypeNames["COMMENT_UPDATE"] = "COMMENT_UPDATE";
    serviceTypeNames["COURSE_GET"] = "COURSE_GET";
    serviceTypeNames["COURSE_LIST"] = "COURSE_LIST";
    serviceTypeNames["FILTERED_PRODUCTS_LOADING"] = "FILTERED_PRODUCTS_LOADING";
    serviceTypeNames["NOTIFICATION_LIST"] = "NOTIFICATION_LIST";
    serviceTypeNames["NOTIFICATION_UPDATE"] = "NOTIFICATION_UPDATE";
    serviceTypeNames["OBJECT_GET"] = "OBJECT_GET";
    serviceTypeNames["PAYMENT_CONFIRM"] = "PAYMENT_CONFIRM";
    // PAYMENT_CREATE = "PAYMENT_CREATE",
    serviceTypeNames["PAYMENT_GATEWAY_GET"] = "PAYMENT_GATEWAY_GET";
    serviceTypeNames["PAYMENT_GATEWAY_LIST"] = "PAYMENT_GATEWAY_LIST";
    // PAYMENT_INIT = "PAYMENT_INIT",
    serviceTypeNames["PAYMENT_METHOD_CREATE"] = "PAYMENT_METHOD_CREATE";
    serviceTypeNames["PAYMENT_METHOD_GET"] = "PAYMENT_METHOD_GET";
    serviceTypeNames["PAYMENT_METHOD_LIST"] = "PAYMENT_METHOD_LIST";
    // PAYMENT_START = "PAYMENT_START",
    serviceTypeNames["PURCHASES_LIST"] = "PURCHASES_LIST";
    serviceTypeNames["SALES_INVOICE_GET"] = "SALES_INVOICE_GET";
    serviceTypeNames["SALES_ORDER_GET"] = "SALES_ORDER_GET";
    serviceTypeNames["SALES_ORDER_LIST"] = "SALES_ORDER_LIST";
    serviceTypeNames["SCENARIO_SG01_X00001"] = "SCENARIO_SG01_X00001";
    serviceTypeNames["SCENARIO_SG01_X00002"] = "SCENARIO_SG01_X00002";
    serviceTypeNames["SCENARIO_SG10_X00029"] = "SCENARIO_SG10_X00029";
    serviceTypeNames["SHORTLIST_CREATE"] = "SHORTLIST_CREATE";
    serviceTypeNames["SHORTLIST_DELETE"] = "SHORTLIST_DELETE";
    serviceTypeNames["SHORTLIST_GET"] = "SHORTLIST_GET";
    serviceTypeNames["SHORTLIST_ITEM_CREATE"] = "SHORTLIST_ITEM_CREATE";
    serviceTypeNames["SHORTLIST_ITEM_DELETE"] = "SHORTLIST_ITEM_DELETE";
    serviceTypeNames["SHORTLIST_ITEM_GET"] = "SHORTLIST_ITEM_GET";
    serviceTypeNames["SHORTLIST_ITEM_LIST"] = "SHORTLIST_ITEM_LIST";
    serviceTypeNames["SHORTLIST_ITEM_UPDATE"] = "SHORTLIST_ITEM_UPDATE";
    serviceTypeNames["SHORTLIST_LIST"] = "SHORTLIST_LIST";
    serviceTypeNames["SUBSCRIPTION_LIST"] = "SUBSCRIPTION_LIST";
    serviceTypeNames["USER_ACTIVATE"] = "SCENARIO_SG03_X00008";
    serviceTypeNames["USER_CREATE"] = "USER_CREATE";
    serviceTypeNames["USER_GET_PROFILE"] = "SCENARIO_SG03_X00012";
    serviceTypeNames["USER_LIST"] = "USER_LIST";
    serviceTypeNames["USER_LOGIN"] = "SCENARIO_SG03_X00009";
    serviceTypeNames["USER_LOGOUT"] = "USER_LOGOUT";
    serviceTypeNames["USER_LOST_PASSWORD"] = "SCENARIO_SG03_X00010";
    serviceTypeNames["USER_RECOVER_PASSWORD"] = "SCENARIO_SG03_X00011";
    serviceTypeNames["USER_REGISTER"] = "SCENARIO_SG03_X00007";
    serviceTypeNames["USER_REMOVE"] = "USER_REMOVE";
    serviceTypeNames["USER_UPDATE_PASSWORD"] = "SCENARIO_SG03_X00014";
    serviceTypeNames["USER_UPDATE_PROFILE"] = "SCENARIO_SG03_X00013";
    serviceTypeNames["VOUCHER_LIST"] = "VOUCHER_LIST";
    serviceTypeNames["VOUCHER_REDEEM"] = "VOUCHER_REDEEM";
    serviceTypeNames["SCENARIO_SG10_X00030"] = "SCENARIO_SG10_X00030";
    serviceTypeNames["SCENARIO_SG10_X00031"] = "SCENARIO_SG10_X00031";
    serviceTypeNames["SCENARIO_SG10_X00032"] = "SCENARIO_SG10_X00032";
    serviceTypeNames["CLASS_GET"] = "CLASS_GET";
    serviceTypeNames["CLASS_LIST"] = "CLASS_LIST";
    serviceTypeNames["CLASS_CREATE"] = "CLASS_CREATE";
    serviceTypeNames["CLASS_UPDATE"] = "CLASS_UPDATE";
    serviceTypeNames["EDUCATION_GET"] = "EDUCATION_GET";
    serviceTypeNames["EDUCATION_LIST"] = "EDUCATION_LIST";
    serviceTypeNames["SUBSCRIPTION_GET"] = "SUBSCRIPTION_GET";
})(serviceTypeNames || (serviceTypeNames = {}));
class Service {
    nodeUniqueId;
    auth;
    url;
    constructor() {
        this.nodeUniqueId = process.env.VUE_APP_EUSDP_NODE_UNIQUE_ID;
        this.auth = {
            username: process.env.VUE_APP_EUSDP_SERVICE_API_KEY,
            password: process.env.VUE_APP_EUSDP_SERVICE_API_SECRET,
        };
        this.url =
            process.env.VUE_APP_EUSDP_SERVICE_URL +
                '/' +
                process.env.VUE_APP_EUSDP_SERVICE_PORTFOLIO +
                '/info/' +
                process.env.VUE_APP_EUSDP_SERVICE_VERSION;
    }
    /**
     * @param {String} type
     * @param {Object} payload
     * @param {String} mode
     * @param {String} context
     */
    // eslint-disable-next-line
    request(type, payload, context = 'USER', mode = '') {
        return new Promise((resolve, reject) => {
            const requestData = createInfoObject(type, payload, context, mode);
            let auth;
            switch (context) {
                case 'USER':
                case 'ADMIN':
                case 'ACCOUNT':
                    auth = getBearerAuth();
                    break;
                case 'PUBLIC':
                    auth = {
                        auth: this.auth,
                    };
                    break;
            }
            axios
                .post(this.url, requestData, auth)
                .then((response) => resolve(response.data.data))
                .catch((error) => reject(error));
        });
    }
}
export default Service;
