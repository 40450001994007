export var productCategoryPresentationTypeNames;
(function (productCategoryPresentationTypeNames) {
    productCategoryPresentationTypeNames["SINGLE_PRODUCT_CATEGORY"] = "SINGLE_PRODUCT_CATEGORY";
    productCategoryPresentationTypeNames["SINGLE_PRODUCT_CATEGORY_GROUP"] = "SINGLE_PRODUCT_CATEGORY_GROUP";
})(productCategoryPresentationTypeNames || (productCategoryPresentationTypeNames = {}));
const getProductCategoryView = (productCategory) => {
    if (productCategory && productCategory.length > 0) {
        return productCategory[0].productCategoryPresentationType;
    }
    return null;
};
export const getProductRouteByCategory = (product) => {
    const productCategoryPresentationType = getProductCategoryView(product.productCategories);
    let routeObject = {};
    switch (productCategoryPresentationType) {
        case productCategoryPresentationTypeNames.SINGLE_PRODUCT_CATEGORY:
            routeObject = {
                name: "product-single",
                params: {
                    uuid: product.productUniqueId,
                },
            };
            break;
        case productCategoryPresentationTypeNames.SINGLE_PRODUCT_CATEGORY_GROUP:
            routeObject = {
                name: "single-product-category",
                params: {
                    category: product.productGroups[0].productGroupDescShort,
                    uuid: product.productUniqueId,
                },
            };
            break;
        default:
            routeObject = {
                name: "product-single",
                params: {
                    uuid: product.productUniqueId,
                },
            };
    }
    return routeObject;
};
