import { onMounted, onBeforeUnmount } from "vue";
export function useClickOutSide(target_ref, callback) {
    if (!target_ref)
        return;
    const listener = (e) => {
        if (target_ref.value) {
            if (e.target === target_ref.value ||
                e.composedPath().includes(target_ref.value)) {
                return;
            }
        }
        if (typeof callback === "function") {
            if (e.target)
                callback(e);
            else
                callback();
        }
    };
    onBeforeUnmount(() => {
        document.addEventListener("click", listener);
    });
    onMounted(() => {
        document.addEventListener("click", listener);
    });
    return {
        listener,
    };
}
