<template>
    <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.3125 18.0625L0.5625 16.3125L7.5625 9.3125L0.5625 2.3125L2.3125 0.5625L9.3125 7.5625L16.3125 0.5625L18.0625 2.3125L11.0625 9.3125L18.0625 16.3125L16.3125 18.0625L9.3125 11.0625L2.3125 18.0625Z" fill="white"/>
</svg>

</template>

<script setup lang="ts">

</script>

<style scoped>

</style>