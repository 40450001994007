import { createI18n } from "vue-i18n";
import de_DE from "@/i18n/de-DE.json";
import en_US from "@/i18n/en-US.json";
import it_IT from "@/i18n/it-IT.json";
import fr_FR from "@/i18n/fr-FR.json";
import hr_HRV from "@/i18n/hr-HRV.json";
import el_GR from "@/i18n/el-GR.json";
import getCookie from "@/lib/get-cookie";
const messages = {
    "en-US": {
        ...en_US,
    },
    "it-IT": {
        ...it_IT,
    },
    "fr-FR": {
        ...fr_FR,
    },
    "de-DE": {
        ...de_DE,
    },
    "hr-HRV": {
        ...hr_HRV,
    },
    "el-GR": {
        ...el_GR,
    },
};
let locale = "en-US";
const localeCookie = getCookie("locale", false);
if (localeCookie &&
    ["en-US", "de-DE", "it-IT", "fr-FR", "hr-HRV", "el-GR"].includes(localeCookie)) {
    locale = localeCookie;
}
const instance = createI18n({
    fallbackLocale: "en-US",
    locale,
    messages,
    fallbackWarn: false,
    missingWarn: false,
    silentTranslationWarn: true,
    silentFallbackWarn: true,
    legacy: false,
    // allowComposition: true,
    // mode:'composition'
});
export default instance;
export const i18n = instance.global;
