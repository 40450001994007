import { i18n } from '@/i18n';
import { Context } from '@/interfaces/application';
import deleteCookie from '@/lib/delete-cookie';
import getCookie from '@/lib/get-cookie';
import { getError } from '@/lib/get-formatted-error';
import { sendRequest } from '@/lib/request';
import { serviceTypeNames } from '@/lib/service';
import setCookie from '@/lib/set-cookie';
import { defineStore } from 'pinia';
import { loadingNames, useLoadingStore } from '../app/loading.module';
import { useCartStore } from './cart.module';
import { usePaymentStore } from './payment.module';
import { useProductStore } from './product.module';
import { useToastStore } from '../app/toast.module';
import { useTableStore } from './table.module';
const user = getCookie('user');
const profile = null;
const state = user
    ? { status: { loggedIn: true }, user, profile }
    : { status: { loggedIn: false }, user: null, profile };
export const useUserStore = defineStore('userModule', {
    state: () => ({
        status: {
            loggedIn: state.status.loggedIn,
        },
        user: state.user,
        profile: state.profile,
        users: null,
        customerGroups: [],
        customerGroup: null,
        postRegistrationData: null
    }),
    getters: {
        isLoggedIn: (state) => {
            return state.user !== null;
        },
        fullName: (state) => {
            if (!state.profile || (!state.profile.person.firstName && !state.profile.person.lastName))
                return undefined;
            if (!state.profile.person.lastName && state.profile.person.firstName)
                return state.profile.person.firstName;
            if (!state.profile.person.firstName && state.profile.person.lastName)
                return state.profile.person.lastName;
            return state.profile.person.firstName + ' ' + state.profile.person.lastName;
        },
        userName: (state) => {
            if (!state.profile)
                return undefined;
            return state.profile.authenticationMethod.username;
        },
        avatar: (state) => {
            if (!state.profile ||
                (!state.profile.person.firstName &&
                    !state.profile.person.lastName &&
                    !state.profile.person.avatar))
                return undefined;
            if (!state.profile.person.avatar) {
                if (!state.profile.person.lastName && state.profile.person.firstName) {
                    return `https://ui-avatars.com/api/?name=${state.profile.person.firstName}`;
                }
                if (!state.profile.person.firstName && state.profile.person.lastName) {
                    return `https://ui-avatars.com/api/?name=${state.profile.person.lastName}`;
                }
                return `https://ui-avatars.com/api/?name=${state.profile.person.firstName}+${state.profile.person.lastName}`;
            }
            return state.profile.person.avatar;
        },
        isIndividual: (state) => {
            return state.profile && state.profile.accountType.indexOf('INDIVIDUAL') > -1 ? true : false;
        },
        // isResellerDistributor: (state: UserState) => {
        //   if (!state.profile || !state.customerGroups) return null;
        //   const foundObj = state.customerGroups.find(
        //     (group) =>
        //       group.customerGroupDescShort.toLowerCase().indexOf('reseller') > -1 ||
        //       group.customerGroupDescShort.toLowerCase().indexOf('distributor') > -1,
        //   );
        //   if(foundObj) return true;
        //   return false
        // },
        // isProfessionalOrSalon: (state: UserState) => {
        //   if (!state.profile || !state.customerGroups) return null;
        //   const foundObj = state.customerGroups.find(
        //     (group) =>
        //       group.customerGroupDescShort.toLowerCase().indexOf('salon') > -1 ||
        //       group.customerGroupDescShort.toLowerCase().indexOf('professional') > -1,
        //   );
        //   if(foundObj) return true;
        //   return false
        // },
    },
    actions: {
        async clearUser() {
            this.user = null;
            this.profile = null;
            const productStore = useProductStore();
            const cartStore = useCartStore();
            cartStore.CLEAR_CART_STORE();
            productStore.resetProductFilter();
        },
        populateUser(user) {
            this.user = user;
        },
        populateProfile(profile) {
            this.profile = profile;
        },
        async REGISTER(payload) {
            try {
                const res = await sendRequest(serviceTypeNames.USER_REGISTER, {
                    ...payload,
                    channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
                }, loadingNames.USER_REGISTER, 'Success', Context.Public);
                return res;
            }
            catch (error) {
                const err = getError(error);
                this.clearUser();
                return Promise.reject(error);
            }
        },
        async LOGIN(authenticationMethod) {
            try {
                const payload = {
                    channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
                    authenticationMethodType: 'AUTHENTICATION_METHOD_SYSTEM_USERNAME',
                    ...authenticationMethod,
                };
                const user = await sendRequest(serviceTypeNames.USER_LOGIN, payload, loadingNames.USER_LOGIN, 'Logged in successfully', Context.Public);
                setCookie('user', JSON.stringify({
                    key: user.key,
                    secret: user.secret,
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    token: user.token,
                    partyUniqueId: null,
                    awsAccessKeyId: user.aws.key,
                    awsSecretAccessKey: user.aws.secret,
                }), {
                    expires: 86400,
                });
                this.populateUser(user);
                // const cart = useCartStore();
                // await cart.CART_OBTAIN({
                //   cartUniqueId: null,
                //   currency: 'EUR',
                // });
                // const productStore = useProductStore();
                // productStore.resetProductFilter();
                return user;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        LOGOUT() {
            // loading store
            const productStore = useProductStore();
            const loadingStore = useLoadingStore();
            loadingStore.setLoadingState(serviceTypeNames.USER_LOGOUT, true);
            return new Promise((resolve) => {
                deleteCookie('user');
                const tableStore = useTableStore();
                const cart = useCartStore();
                const payment = usePaymentStore();
                deleteCookie('tables');
                localStorage.removeItem('tableModule');
                tableStore.clear();
                payment.CLEAR_PAYMENT_STORE();
                productStore.resetProducts();
                cart.CLEAR_CART_STORE(false);
                this.clearUser();
                loadingStore.setLoadingState(serviceTypeNames.USER_LOGOUT, false);
                resolve(true);
            });
        },
        async LOST_PASSWORD(username) {
            try {
                const payload = {
                    channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
                    username,
                    passwordResetUrl: process.env.VUE_APP_APPLICATION_DOMAIN + '/password-recovery',
                    passwordResetExpiresInSeconds: 86400,
                };
                await sendRequest(serviceTypeNames.USER_LOST_PASSWORD, payload, loadingNames.USER_LOST_PASSWORD, i18n.t('toasts.success.messages.password-reset'), Context.Public);
                return true;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async RECOVER_PASSWORD(recoverPasswordOptions) {
            try {
                const payload = {
                    channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
                    ...recoverPasswordOptions,
                };
                await sendRequest(serviceTypeNames.USER_RECOVER_PASSWORD, payload, loadingNames.USER_RECOVER_PASSWORD, 'Password updated successfully', Context.Public);
                return true;
            }
            catch (error) {
                const err = getError(error);
                return Promise.reject(error);
            }
        },
        async GET_PROFILE() {
            // loading store
            try {
                const profile = await sendRequest(serviceTypeNames.USER_GET_PROFILE, null, loadingNames.USER_GET_PROFILE);
                this.populateProfile(profile);
                return profile;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async UPDATE_PROFILE(user) {
            try {
                const profile = await sendRequest(serviceTypeNames.USER_UPDATE_PROFILE, user, loadingNames.USER_UPDATE_PROFILE, 'Profile Updated Successfully');
                this.populateProfile(profile);
                return profile;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async UPDATE_PASSWORD(updatePasswordOptions) {
            try {
                const profile = await sendRequest(serviceTypeNames.USER_UPDATE_PASSWORD, {
                    username: updatePasswordOptions.username,
                    password: updatePasswordOptions.password,
                    newPassword: updatePasswordOptions.newPassword,
                }, loadingNames.USER_UPDATE_PASSWORD, 'Password updated successfully');
                return profile;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async LIST(options, context = Context.Account) {
            try {
                const res = await sendRequest(serviceTypeNames.USER_LIST, options, undefined, undefined, context);
                this.users = res;
                return res;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async ADD(payload, editMode = false) {
            const users = this.users
                ? {
                    page: this.users.page,
                    limit: null,
                    itemsPerPage: this.users.itemsPerPage,
                    order: null,
                    filter: null,
                }
                : { page: 1, limit: null, itemsPerPage: 10, filter: null, order: null };
            try {
                const requestType = editMode
                    ? serviceTypeNames.USER_UPDATE_PROFILE
                    : serviceTypeNames.USER_CREATE;
                const successMsg = editMode ? 'User Updated Successfully' : 'User Added Successfully';
                const options = {
                    channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
                    ...payload,
                };
                await sendRequest(requestType, options);
                const res = await sendRequest(serviceTypeNames.USER_LIST, users, undefined, successMsg, Context.Account);
                this.users = res;
                return true;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async REMOVE(userUniqueId, listUsers) {
            const users = this.users
                ? {
                    page: this.users.page,
                    limit: null,
                    itemsPerPage: this.users.itemsPerPage,
                    order: null,
                    filter: null,
                }
                : { page: 1, limit: null, itemsPerPage: 10, filter: null, order: null };
            try {
                const options = {
                    channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
                    userUniqueId,
                };
                await sendRequest(serviceTypeNames.USER_REMOVE, options);
                if (listUsers) {
                    const res = await sendRequest(serviceTypeNames.USER_LIST, users);
                    this.users = res;
                }
                return true;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async VERIFY_VAT(payload) {
            const toastStore = useToastStore();
            try {
                const data = await sendRequest(serviceTypeNames.VERIFY_VAT, payload, loadingNames.VERIFY_VAT, '', Context.Public);
                if (!data.vatVerified) {
                    const err = new Error('The provided EU VAT tax number can not be verified. Please ensure that the provided EU VAT tax number is valid.');
                    err.name = 'VAT_VERIFICATION_FAILED';
                    throw err;
                }
                return data;
            }
            catch (error) {
                if (error.name === 'VAT_VERIFICATION_FAILED') {
                    toastStore.danger({
                        title: 'Error',
                        message: error.message,
                    });
                }
                return Promise.reject(error);
            }
        },
        // async SET_CUSTOMER_GROUP(): Promise<any> {
        //   const res = await sendRequest(serviceTypeNames.SCENARIO_SG01_X00002, {});
        //   this.customerGroups = res.customerGroups;
        // },
    },
    persist: {
        paths: ['profile'],
    },
});
