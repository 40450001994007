import colorData from '@/assets/json/colors_v1.0.json';
import { filterProducts } from '@/lib/get-product-filter';
import { getProductPrice, getProductPriceObject } from '@/lib/get-product-price';
import axios from 'axios';
export var ShortlistTypes;
(function (ShortlistTypes) {
    ShortlistTypes["SHORTLIST"] = "SHORTLIST_SHORTLIST";
    ShortlistTypes["WATCHLIST"] = "SHORTLIST_WATCHLIST";
})(ShortlistTypes || (ShortlistTypes = {}));
import { sortOptions, sortProductsBy } from '@/lib/get-sorted-products';
import { getGalleryLayout } from '@/lib/image-layout-parser';
import { sendRequest } from '@/lib/request';
import { serviceTypeNames } from '@/lib/service';
import { defineStore } from 'pinia';
import { loadingNames, useLoadingStore } from '../app/loading.module';
import { useToastStore } from '../app/toast.module';
import { useFavoritesStore } from './favorites.module';
import { useUserStore } from './user.module';
export const useProductStore = defineStore('productModule', {
    state: () => ({
        product: {},
        products: {
            //filtered Products as per JSON data coming
            // could not change the property name of 'products' key as 'filteredProducts' because the structure was decided later after everything was implemented.
            total: 0,
            page: 1,
            paginationButtons: [],
            itemsPerPage: 12,
            list: [],
        },
        allProducts: {
            total: 0,
            page: 1,
            paginationButtons: [],
            itemsPerPage: 12,
            list: [],
        },
        likeProducts: [],
        filterObject: {
            filter: { filterByLocation: true },
            products: [],
        },
        sortObject: {
            sortBy: sortOptions.ORDER_ASC,
            products: [],
        },
        wishlist: [],
        page: 1,
        itemsPerPage: 12,
        paginationButtons: [],
        excludedSortingOptions: ['POPULARITY'],
        // buttonsLimit:5,
    }),
    getters: {
        getGalleryLayout(state) {
            return getGalleryLayout(state.product?.mediaObjects);
        },
        getLikeProductsWithPrice(state) {
            return state.likeProducts.map((item) => {
                const itemPrice = getProductPrice(item, 'CONSUMER_SALES_PRICE_LIST_EUR');
                //add new property to the item
                return { ...item, productPrice: itemPrice?.productPriceNet };
            });
        },
        getButtons(state) {
            return state.paginationButtons;
        },
        getFilteredProducts(state) {
            const filteredProducts = filterProducts(state.filterObject);
            return filteredProducts;
        },
        getSortedProducts(state) {
            const sortedProducts = sortProductsBy({
                ...state.sortObject,
                products: this.getFilteredProducts,
            });
            return sortedProducts;
        },
        getButtonsLimit(state) {
            const filteredProducts = filterProducts(state.filterObject);
            const totalPages = Math.ceil(filteredProducts.length / this.products.itemsPerPage);
            return totalPages > 5 ? 5 : totalPages;
        },
        getShopProducts(state) {
            const filteredProducts = this.getSortedProducts;
            console.log(state.page, 'PAGE');
            const trimStart = (state.page - 1) * state.products.itemsPerPage;
            const trimEnd = trimStart + state.products.itemsPerPage;
            const trimmedData = filteredProducts.slice(trimStart, trimEnd);
            return trimmedData;
        },
        getProduct(state) {
            return state.product;
        },
        getProductColors(state) {
            return colorData;
        },
        getshopName(state) {
            return state.shopname;
        },
        getThumbnails(state) {
            let thumbnails = [];
            thumbnails = [
                {
                    uuid: 'u1',
                    img: require('@/assets/img/toptellers/shop/product/product.jpg'),
                    active: false,
                },
            ];
            return thumbnails;
        },
        getProductPrice(state) {
            return getProductPriceObject(state.product, state.product ? state.product.customerGroupUniqueId : undefined);
        },
        getTotalPages(state) {
            return Math.ceil(this.getFilteredProducts.length / state.itemsPerPage);
        },
        getUserWishList(state) {
            const userStore = useUserStore();
            if (userStore.profile && userStore.profile.accountUniqueId) {
                const wishlist = state.wishlist.find((wishlist) => wishlist.userID === userStore.profile?.accountUniqueId);
                if (wishlist) {
                    return wishlist;
                }
                else {
                    return null;
                }
            }
        },
    },
    actions: {
        clearWishList() {
            this.wishlist = [];
        },
        getProductPrices(product) {
            return getProductPriceObject(product, product ? product.customerGroupUniqueId : undefined);
        },
        async getWishList() {
            const userStore = useUserStore();
            const favouriteStore = useFavoritesStore();
            if (userStore.profile && userStore.profile.accountUniqueId) {
                const wishlist = this.wishlist.find((wishlist) => wishlist.userID === userStore.profile?.accountUniqueId);
                const listRes = await favouriteStore.LIST({
                    filter: {
                        'meta_owner_account.gen_account_unique_id': userStore.profile?.accountUniqueId,
                    },
                    order: {},
                    page: 1,
                    itemsPerPage: 50,
                    limit: null,
                }, 'SHORTLIST_LIST');
                const foundShortlist = listRes.list.find((item) => item.shortlistType === 'SHORTLIST_FAVORITES');
                const listItemRes = await favouriteStore.LIST_ITEM({
                    filter: {
                        "gen_shortlist.gen_shortlist_unique_id": foundShortlist.shortlistUniqueId
                    },
                    order: {},
                    page: 1,
                    itemsPerPage: 50,
                    limit: null,
                });
                const wishlisthProducts = listItemRes.list.map((item) => {
                    // if (item.shortlistUniqueId === foundShortlist.shortlistUniqueId) {
                    return {
                        productId: item.productUniqueId,
                    };
                    // }
                });
                this.wishlist = [];
                this.wishlist.push({
                    userID: userStore.profile?.accountUniqueId,
                    products: wishlisthProducts,
                });
            }
        },
        isProductInWishList(id) {
            const userWishlist = this.getUserWishList;
            if (userWishlist && userWishlist.products) {
                const result = userWishlist.products.find((product) => product && product.productId === id);
                if (result && result.productId) {
                    return true;
                }
                else {
                    return false;
                }
            }
        },
        addProductToWishList(id, shortlistType) {
            const user = useUserStore();
            const toastStore = useToastStore();
            const productToAdd = this.products.list.find((product) => product.productUniqueId === id);
            if (user.profile && user.profile.accountUniqueId) {
                //find wishlist object whose user id matches with current user id
                const wishlist = this.wishlist.find((wishlist) => wishlist.userID === user.profile?.accountUniqueId);
                //if wishlist object is found add productToAdd to wishlist
                if (wishlist && wishlist.products) {
                    wishlist.products.push({ productId: id });
                    toastStore.success({
                        title: 'Success',
                        message: shortlistType === ShortlistTypes.WATCHLIST
                            ? 'Course added to your watchlist!'
                            : 'Product added to your shortlist!',
                    });
                }
                else {
                    //if wishlist object is not found create new wishlist object and add productToAdd to wishlist
                    this.wishlist.push({
                        userID: user.profile?.accountUniqueId,
                        products: [{ productId: id }],
                    });
                    toastStore.success({
                        title: 'Success',
                        message: shortlistType === ShortlistTypes.WATCHLIST
                            ? 'Course added to your watchlist!'
                            : 'Product added to your shortlist!',
                    });
                }
            }
        },
        removeProductFromWishList(id, shortlistType) {
            const toastStore = useToastStore();
            const userWishlist = this.getUserWishList;
            // const foundIndex = getUserWishList()?.products.findIndex(
            if (userWishlist !== null && userWishlist?.products) {
                const foundIndex = userWishlist.products.findIndex((product) => product.productId === id);
                if (foundIndex !== -1) {
                    userWishlist.products.splice(foundIndex, 1);
                    toastStore.success({
                        title: 'Success',
                        message: shortlistType === ShortlistTypes.WATCHLIST
                            ? 'Course removed from your watchlist!'
                            : 'Product removed from your shortlist!',
                    });
                }
            }
        },
        toogleProductWishList(productUniqueId, type, shortlistType) {
            const userWishlist = this.getUserWishList;
            if (userWishlist !== null && userWishlist?.products) {
                if (type === 'remove') {
                    // remove
                    this.removeProductFromWishList(productUniqueId, shortlistType);
                }
                else {
                    // add
                    console.log('running --- else add part');
                    this.addProductToWishList(productUniqueId, shortlistType);
                }
            }
            else {
                this.addProductToWishList(productUniqueId, shortlistType);
            }
        },
        resetPagination() {
            this.products.page = 1;
            this.setPaginationButtons(false, true);
        },
        setPaginationButtons(goback, initial) {
            // set pagination buttons
            const buttonsLimit = this.getButtonsLimit;
            let subtractEnd = 1;
            if (!initial) {
                subtractEnd = 0;
            }
            let start = this.page;
            let end = start + buttonsLimit - subtractEnd;
            if (goback) {
                let addStart = 0;
                if (this.page === buttonsLimit) {
                    addStart = 1;
                }
                start = this.page - buttonsLimit + addStart;
                end = this.page;
            }
            this.paginationButtons = [];
            while (start <= end) {
                this.paginationButtons.push(start++);
            }
        },
        nextPage() {
            if (this.page < Math.ceil(this.products.total / this.products.itemsPerPage)) {
                this.page++;
            }
            this.setPaginationButtons();
        },
        prevPage() {
            if (this.page > 1)
                this.page--;
            this.setPaginationButtons();
        },
        setPage(page) {
            this.page = page;
            if (page < 1 || page > this.getTotalPages)
                return;
            if (this.page === this.paginationButtons[this.paginationButtons.length - 1]) {
                this.setPaginationButtons();
            }
            else if (this.page === this.paginationButtons[0] && this.page !== 1) {
                this.setPaginationButtons(true);
            }
        },
        setSortByObject(sortBy) {
            this.sortObject = sortBy;
        },
        setProducts(filterObject) {
            if (filterObject) {
                const filteredProducts = filterProducts(filterObject);
                this.products.list = filteredProducts;
                this.products.total = filteredProducts.length;
            }
            const trimStart = (this.products.page - 1) * this.products.itemsPerPage;
            const trimEnd = trimStart + this.products.itemsPerPage;
            const trimmedData = this.products.list.slice(trimStart, trimEnd);
            this.products.list = trimmedData;
            this.page = 1;
            this.paginationButtons = [];
        },
        async setProduct(id) {
            const product = this.products.list.find((item) => item.productUniqueId === id);
            if (!product) {
                // await this.SET_PRODUCTS('/json/final_v1.0.json');
                const product = this.allProducts.list.find((item) => item.productUniqueId === id);
                Object.assign(this.product, product);
                return product;
            }
            else {
                Object.assign(this.product, product);
                return product;
            }
        },
        async setProductFilter(filterObject, page = 1) {
            this.page = !isNaN(+page) ? +page : 1;
            this.setPaginationButtons(this.page > 1 ? true : false, this.page > 1 ? false : true);
            if (filterObject.filter?.color?.length && !this.allProducts.list.length) {
                await this.SET_PRODUCTS('/json/final_v1.0.json');
                this.filterObject.products = this.allProducts.list;
            }
            else if (!this.products.list.length) {
                await this.SET_PRODUCTS('/json/filtered_v1.0.json', 'FILTER');
                this.filterObject.products = this.products.list;
            }
            const filter = {
                ...this.filterObject.filter,
                ...filterObject.filter,
            };
            let useFilteredProducts = true;
            let sizeContentFiltered = false;
            if (filter.sizeContent) {
                for (const key in filter.sizeContent) {
                    const _key = key;
                    if (filter.sizeContent != null && filter.sizeContent[_key]?.length) {
                        sizeContentFiltered = true;
                        break;
                    }
                }
            }
            if (filter.color?.length) {
                useFilteredProducts = false;
            }
            this.filterObject = {
                filter,
                products: useFilteredProducts ? this.products.list : this.allProducts.list,
            };
        },
        // setFilterObject(filterObject: filterObjectProps) {
        //   this.filterObject = filterObject;
        // },
        setProductSortObject(sortObject) {
            this.sortObject = {
                sortBy: sortObject.sortBy,
                products: this.getFilteredProducts,
            };
        },
        resetProductFilter() {
            delete this.filterObject.filter;
            this.filterObject.products = this.products.list;
        },
        resetProducts() {
            this.allProducts.list = [];
            this.allProducts.total = 0;
            this.products.list = [];
            this.products.total = 0;
            this.resetProductFilter();
        },
        async SET_FILTERED_PRODUCTS() {
            const loadingStore = useLoadingStore();
            loadingStore.setLoadingState(loadingNames.FILTERED_PRODUCTS_LOADING, true);
            try {
                const res = await axios('/json/filtered_v1.0.json');
                this.products.list = filterProducts({
                    filter: { filterByLocation: true },
                    products: Object.values(res.data),
                }).map((item) => {
                    item.mediaObjects = item.mediaObjects.map((media) => {
                        if (process.env.VUE_APP_APPLICATION_ENV === 'production' &&
                            process.env.VUE_APP_DELIVERY_GENERAL_AWS_CF) {
                            media.mediaObjectPrivateUri = `${process.env.VUE_APP_DELIVERY_GENERAL_AWS_CF}library/${media.mediaObjectPrivateUri}`;
                            media.mediaObjectPublicUri = `${process.env.VUE_APP_DELIVERY_GENERAL_AWS_CF}library/${media.mediaObjectPublicUri}`;
                            return media;
                        }
                        else {
                            media.mediaObjectPrivateUri = `${process.env.VUE_APP_STORAGE_ASSETS_AWS_S3_BUCKET_URL}${process.env.VUE_APP_STORAGE_ASSETS_LOCAL}${media.mediaObjectPrivateUri}`;
                            media.mediaObjectPublicUri = `${process.env.VUE_APP_STORAGE_ASSETS_AWS_S3_BUCKET_URL}${process.env.VUE_APP_STORAGE_ASSETS_LOCAL}${media.mediaObjectPublicUri}`;
                            return media;
                        }
                    });
                    return item;
                });
                this.products.total = res.data.length;
                this.filterObject.products = this.products.list;
                this.likeProducts = res.data.filter((item) => {
                    if (item.productGroups &&
                        item.productGroups[0] &&
                        item.productGroups[0].productGroupDescShort) {
                        if (!item.productGroups[0].productGroupDescShort.includes('Pre Bonded Hair Extensions') &&
                            !item.productGroups[0].productGroupDescShort.includes('Clip in Extensions') &&
                            !item.productGroups[0].productGroupDescShort.includes('Tape Hair Extensions') &&
                            !item.productGroups[0].productGroupDescShort.includes('Weft Hair Extensions')) {
                            return item;
                        }
                    }
                });
            }
            catch (error) {
                console.log(error);
            }
            finally {
                loadingStore.setLoadingState(loadingNames.FILTERED_PRODUCTS_LOADING, false);
            }
        },
        async SET_PRODUCTS(url, type = 'ALL', load = true) {
            const userStore = useUserStore();
            const loadingStore = useLoadingStore();
            loadingStore.setLoadingState(loadingNames.FILTERED_PRODUCTS_LOADING, load);
            try {
                const products = await axios(url);
                let userGroupUniqueId = null;
                if (userStore.isLoggedIn) {
                    const userGroupRes = await sendRequest(serviceTypeNames.SCENARIO_SG01_X00002, {});
                    userGroupUniqueId = userGroupRes.customerGroups[0].customerGroupUniqueId;
                    if (userGroupRes.customerGroups.length) {
                        userStore.customerGroups = [...userGroupRes.customerGroups];
                        userStore.customerGroup = {
                            customerGroupUniqueId: userGroupRes.customerGroups[0].customerGroupUniqueId,
                            customerGroupDescShort: userGroupRes.customerGroups[0].customerGroupDescShort,
                        };
                    }
                }
                const prodArrKey = type === 'ALL' ? 'allProducts' : 'products';
                this[prodArrKey].list = Object.values(products.data).map((item) => {
                    item.mediaObjects = item.mediaObjects.map((media) => {
                        if (process.env.VUE_APP_APPLICATION_ENV === 'production' &&
                            process.env.VUE_APP_DELIVERY_GENERAL_AWS_CF) {
                            media.mediaObjectPrivateUri = `${process.env.VUE_APP_DELIVERY_GENERAL_AWS_CF}/library/${media.mediaObjectPrivateUri}`;
                            media.mediaObjectPublicUri = `${process.env.VUE_APP_DELIVERY_GENERAL_AWS_CF}/library/${media.mediaObjectPublicUri}`;
                            return media;
                        }
                        else {
                            media.mediaObjectPrivateUri = `${process.env.VUE_APP_STORAGE_ASSETS_AWS_S3_BUCKET_URL}${process.env.VUE_APP_STORAGE_ASSETS_LOCAL}${media.mediaObjectPrivateUri}`;
                            media.mediaObjectPublicUri = `${process.env.VUE_APP_STORAGE_ASSETS_AWS_S3_BUCKET_URL}${process.env.VUE_APP_STORAGE_ASSETS_LOCAL}${media.mediaObjectPublicUri}`;
                            return media;
                        }
                    });
                    item.customerGroupUniqueId = userGroupUniqueId;
                    return item;
                });
                this[prodArrKey].total = products.data.length;
                if (type === 'FILTER') {
                    this.filterObject.products = this.products.list;
                }
                this.likeProducts = products.data.filter((item) => {
                    if (item.productGroups &&
                        item.productGroups[0] &&
                        item.productGroups[0].productGroupDescShort) {
                        if (!item.productGroups[0].productGroupDescShort.includes('Pre Bonded Hair Extensions') &&
                            !item.productGroups[0].productGroupDescShort.includes('Clip in Extensions') &&
                            !item.productGroups[0].productGroupDescShort.includes('Tape Hair Extensions') &&
                            !item.productGroups[0].productGroupDescShort.includes('Weft Hair Extensions')) {
                            return item;
                        }
                    }
                });
                return this[prodArrKey].list;
            }
            catch (error) {
                console.log(error);
            }
            finally {
                loadingStore.setLoadingState(loadingNames.FILTERED_PRODUCTS_LOADING, false);
            }
        },
    },
    persist: {
        paths: ['product', 'wishlist'],
    },
});
