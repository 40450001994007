import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";
export const useToastStore = defineStore("toastModule", {
    state: () => ({
        list: [],
    }),
    actions: {
        add(toast) {
            this.list.push(toast);
            if (!toast.duration) {
                toast.duration = 3000;
            }
            if (toast.duration !== -1) {
                setTimeout(() => {
                    const index = this.list.findIndex((x) => x.id === toast.id);
                    this.list.splice(index, 1);
                }, toast.duration);
            }
        },
        remove(id) {
            const index = this.list.findIndex((x) => x.id === id);
            this.list.splice(index, 1);
        },
        success(payload) {
            this.add({
                type: "success",
                title: payload.title,
                message: payload.message,
                duration: payload.duration,
                id: uuidv4(),
            });
        },
        danger(payload) {
            this.add({
                type: "danger",
                title: payload.title,
                message: payload.message,
                duration: payload.duration,
                id: uuidv4(),
            });
        },
        warning(payload) {
            this.add({
                type: "warning",
                title: payload.title,
                message: payload.message,
                duration: payload.duration,
                id: uuidv4(),
            });
        },
        info(payload) {
            this.add({
                type: "info",
                title: payload.title,
                message: payload.message,
                duration: payload.duration,
                id: uuidv4(),
            });
        },
    },
});
