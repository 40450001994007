export var ImageNames;
(function (ImageNames) {
    //TOP Tellers
    ImageNames["TT_LOGO"] = "toptellers_logo_01.png";
    //HOME PAGE
    ImageNames["LOGO"] = "logo.webp";
    ImageNames["BRANDS"] = "brands.webp";
    ImageNames["SEAMLESS1_BRAND"] = "seamless1-brand2.webp";
    ImageNames["PEPTAME_BRAND"] = "peptame-brand2.webp";
    ImageNames["ABOUT_US"] = "about-us.webp";
    ImageNames["HOME_BANNER"] = "bg.webp";
    //SEAMLESS1 BRAND PAGE
    ImageNames["S1_BRAND_BANNER"] = "s1-bg2.jpg";
    ImageNames["S1_LOGO_BLACK"] = "s1-logo-black.webp";
    ImageNames["S1_ONLINE_EDUCATION"] = "s1-online-education.jpg";
    ImageNames["SEAMLESS1"] = "seamless1.webp";
    ImageNames["S1_HAIR_EXTENSIONS"] = "s1-hair-extensions.jpg";
    ImageNames["S1_KERATIN"] = "s1-keratin.jpg";
    ImageNames["S1_HAIR_CARE"] = "s1-hair-care.jpg";
    ImageNames["S1_CURLING_RODES"] = "s1-curling-rodes.jpg";
    ImageNames["S1_IONIC_BRUSHES"] = "s1-ionic-brushes.jpg";
    ImageNames["S1_COMPARISON_AFTER"] = "s1-comparison-after.webp";
    ImageNames["S1_COMPARISON_BEFORE"] = "s1-comparison-before.webp";
    ImageNames["S1_COMPARISON_AFTER1"] = "s1-comparison-after2.webp";
    ImageNames["S1_COMPARISON_BEFORE2"] = "s1-comparison-before2.webp";
    //PEPTAME BRAND PAGE
    ImageNames["PEPTAME_BRAND_BANNER"] = "peptame-bg2.jpg";
    ImageNames["PEPTAME_LOGO"] = "peptame-logo.webp";
    ImageNames["PEPTAME_LOGO_GREEN"] = "peptame-logo-green.webp";
    ImageNames["PEPTAME_LOGO_SMALL_WHITE"] = "peptame-logo-small-white.webp";
    ImageNames["PEPTAME_HAIRCARE"] = "peptame-peptame-haircare.webp";
    ImageNames["PEPTAME_SYSTEM"] = "peptame-peptame-system.webp";
    ImageNames["PEPTAME_INFRARED_IRONS"] = "peptame-peptame-infrared-irons.webp";
    //S1 ONLINE EDUCATION PAGE
    ImageNames["S1_ONLINE_EDUCATION_BANNER"] = "s1-online-education-bg.jpg";
})(ImageNames || (ImageNames = {}));
export const ImageLabel = {
    //TOP Tellers
    [ImageNames.TT_LOGO]: 'toptellers_logo_01.png',
    //HOME PAGE
    [ImageNames.LOGO]: 'logo.webp',
    [ImageNames.BRANDS]: 'brands.webp',
    [ImageNames.SEAMLESS1_BRAND]: 'seamless1-brand2.webp',
    [ImageNames.PEPTAME_BRAND]: 'peptame-brand2.webp',
    [ImageNames.ABOUT_US]: 'about-us.webp',
    [ImageNames.HOME_BANNER]: 'bg.webp',
    //SEAMLESS1 BRAND PAGE
    [ImageNames.S1_BRAND_BANNER]: 's1-bg2.jpg',
    [ImageNames.S1_LOGO_BLACK]: 's1-logo-black.webp',
    [ImageNames.S1_ONLINE_EDUCATION]: 's1-online-education.jpg',
    [ImageNames.SEAMLESS1]: 'seamless1.webp',
    [ImageNames.S1_HAIR_EXTENSIONS]: 's1-hair-extensions.jpg',
    [ImageNames.S1_KERATIN]: 's1-keratin.jpg',
    [ImageNames.S1_HAIR_CARE]: 's1-hair-care.jpg',
    [ImageNames.S1_CURLING_RODES]: 's1-curling-rodes.jpg',
    [ImageNames.S1_IONIC_BRUSHES]: 's1-ionic-brushes.jpg',
    [ImageNames.S1_COMPARISON_AFTER]: 's1-comparison-after.webp',
    [ImageNames.S1_COMPARISON_BEFORE]: 's1-comparison-before.webp',
    [ImageNames.S1_COMPARISON_AFTER1]: 's1-comparison-after2.webp',
    [ImageNames.S1_COMPARISON_BEFORE2]: 's1-comparison-before2.webp',
    //PEPTAME BRAND PAGE
    [ImageNames.PEPTAME_BRAND_BANNER]: 'peptame-bg2.jpg',
    [ImageNames.PEPTAME_LOGO]: 'peptame-logo.webp',
    [ImageNames.PEPTAME_LOGO_GREEN]: 'peptame-logo-green.webp',
    [ImageNames.PEPTAME_LOGO_SMALL_WHITE]: 'peptame-logo-small-white.webp',
    [ImageNames.PEPTAME_HAIRCARE]: 'peptame-peptame-haircare.webp',
    [ImageNames.PEPTAME_SYSTEM]: 'peptame-peptame-system.webp',
    [ImageNames.PEPTAME_INFRARED_IRONS]: 'peptame-peptame-infrared-irons.webp',
    //S1 ONLINE EDUCATION PAGE
    [ImageNames.S1_ONLINE_EDUCATION_BANNER]: 's1-online-education-bg.jpg',
};
export var URLTypeEnum;
(function (URLTypeEnum) {
    URLTypeEnum["CSS"] = "css";
    URLTypeEnum["DEFAULT"] = "default";
})(URLTypeEnum || (URLTypeEnum = {}));
/**
 *
 * @param name Image name with extension
 * @param type URL type --> can be 'CSS' or 'DEFAULT'
 * @returns Image URL in the CSS form (url('...') or DEFAULT form (http://...))
 */
export function getImageURL(name, type) {
    const ASSET_BASE_URL = process.env.NODE_ENV === 'production'
        ? `${process.env.VUE_APP_DELIVERY_ASSETS_AWS_CF}/library/`
        : `${process.env.VUE_APP_STORAGE_ASSETS_AWS_S3_BUCKET_URL}images/`;
    if (type === 'CSS') {
        const url = `url('${ASSET_BASE_URL}static/${name}')`;
        return url;
    }
    else {
        const url = `${ASSET_BASE_URL}static/${name}`;
        return url;
    }
}
