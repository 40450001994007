import { defineStore } from "pinia";
export const useSidebarStore = defineStore("sidebarModule", {
    state: () => ({
        collapsed: false,
        content: [
            {
                identifier: "dashboard",
                name: {
                    key: "dashboard",
                },
                collapsed: false,
                items: [
                    {
                        name: {
                            key: "overview",
                        },
                        route: "dashboard-overview",
                        icon: "tachometer-alt",
                    },
                ],
            },
            {
                identifier: "media-contents",
                name: {
                    key: "media-content",
                    choice: 2,
                },
                collapsed: false,
                items: [
                    {
                        name: {
                            key: "library",
                            choice: 1,
                        },
                        route: "media-contents-library",
                        icon: "photo-video",
                    },
                    {
                        name: {
                            key: "on-demand",
                        },
                        route: "media-contents-on-demand",
                        icon: "play-circle",
                    },
                    {
                        name: {
                            key: "live",
                        },
                        route: "media-contents-live",
                        icon: "video",
                    },
                    {
                        name: {
                            key: "interactive",
                        },
                        route: "media-contents-interactive",
                        icon: "arrows-alt",
                    },
                    {
                        name: {
                            key: "education",
                        },
                        route: "media-contents-education",
                        icon: "graduation-cap",
                    },
                    {
                        name: {
                            key: "channel",
                            choice: 2,
                        },
                        route: "media-contents-channels",
                        icon: "network-wired",
                    },
                ],
            },
            {
                identifier: "sales",
                name: {
                    key: "sale",
                    choice: 2,
                },
                collapsed: false,
                items: [
                    {
                        name: {
                            key: "customer",
                            choice: 2,
                        },
                        route: "sales-customers",
                        icon: "address-card",
                    },
                    {
                        name: {
                            key: "account",
                            choice: 2,
                        },
                        route: "sales-accounts",
                        icon: ["far", "address-card"],
                    },
                    {
                        name: {
                            key: "order",
                            choice: 2,
                        },
                        route: "sales-orders",
                        icon: "shopping-cart",
                    },
                    {
                        name: {
                            key: "subscription",
                            choice: 2,
                        },
                        route: "sales-subscriptions",
                        icon: "file-invoice",
                    },
                    {
                        name: {
                            key: "voucher",
                            choice: 2,
                        },
                        route: "sales-vouchers",
                        icon: "ticket-alt",
                    },
                    {
                        name: {
                            key: "channel",
                            choice: 2,
                        },
                        route: "sales-channels",
                        icon: "network-wired",
                    },
                ],
            },
            {
                identifier: "products",
                name: {
                    key: "product",
                    choice: 2,
                },
                collapsed: true,
                items: [
                    {
                        name: {
                            key: "item",
                            choice: 2,
                        },
                        route: "products-items",
                        icon: "cube",
                    },
                    {
                        name: {
                            key: "item-group",
                            choice: 2,
                        },
                        route: "products-item-groups",
                        icon: "cubes",
                    },
                    {
                        name: {
                            key: "service-plan",
                            choice: 2,
                        },
                        route: "products-service-plans",
                        icon: "list-alt",
                    },
                ],
            },
            {
                identifier: "automation",
                name: {
                    key: "automation",
                    choice: 1,
                },
                collapsed: true,
                items: [
                    {
                        name: {
                            key: "event",
                            choice: 2,
                        },
                        route: "automation-events",
                        icon: "angle-double-right",
                    },
                    {
                        name: {
                            key: "rule",
                            choice: 2,
                        },
                        route: "automation-rules",
                        icon: "clipboard-check",
                    },
                    {
                        name: {
                            key: "workflow",
                            choice: 2,
                        },
                        route: "automation-workflows",
                        icon: "project-diagram",
                    },
                    {
                        name: {
                            key: "process-schedule",
                            choice: 2,
                        },
                        route: "automation-process-schedule",
                        icon: "clock",
                    },
                    {
                        name: {
                            key: "process-job",
                            choice: 2,
                        },
                        route: "automation-process-jobs",
                        icon: "tasks",
                    },
                    {
                        name: {
                            key: "queue",
                            choice: 2,
                        },
                        route: "automation-queue",
                        icon: "sort-amount-down-alt",
                    },
                ],
            },
            {
                identifier: "storages",
                name: {
                    key: "storage",
                    choice: 2,
                },
                collapsed: true,
                items: [
                    {
                        name: {
                            key: "library",
                            choice: 2,
                        },
                        route: "storage-library",
                        icon: "hdd",
                    },
                    {
                        name: {
                            key: "database",
                            choice: 2,
                        },
                        route: "storage-databases",
                        icon: "database",
                    },
                ],
            },
            {
                identifier: "account",
                name: {
                    key: "account",
                    choice: 1,
                },
                collapsed: true,
                items: [
                    {
                        name: {
                            key: "summary",
                            choice: 1,
                        },
                        route: "account-summary",
                        icon: "newspaper",
                    },
                    {
                        name: {
                            key: "organization",
                            choice: 1,
                        },
                        route: "account-organization",
                        icon: "building",
                    },
                    {
                        name: {
                            key: "product",
                            choice: 2,
                        },
                        route: "account-products",
                        icon: "laptop-code",
                    },
                    {
                        name: {
                            key: "billing",
                            choice: 1,
                        },
                        route: "account-billing",
                        icon: "file-invoice",
                    },
                    {
                        name: {
                            key: "payment-method",
                            choice: 2,
                        },
                        route: "account-payment-methods",
                        icon: "credit-card",
                    },
                ],
            },
            {
                identifier: "settings",
                name: {
                    key: "setting",
                    choice: 2,
                },
                collapsed: true,
                items: [
                    {
                        name: {
                            key: "general",
                        },
                        route: "settings-general",
                        icon: "cog",
                    },
                    {
                        name: {
                            key: "logging",
                        },
                        route: "settings-logging",
                        icon: "file-alt",
                    },
                    {
                        name: {
                            key: "notification",
                            choice: 2,
                        },
                        route: "settings-notifications",
                        icon: "bell",
                    },
                    {
                        name: {
                            key: "merchant",
                            choice: 2,
                        },
                        route: "settings-merchants",
                        icon: "store",
                    },
                    {
                        name: {
                            key: "service-provider",
                            choice: 2,
                        },
                        route: "settings-service-providers",
                        icon: "tools",
                    },
                    {
                        name: {
                            key: "trash-bin",
                        },
                        route: "settings-trash-bin",
                        icon: "trash",
                    },
                ],
            },
            {
                identifier: "security",
                name: {
                    key: "security",
                    choice: 1,
                },
                collapsed: true,
                items: [
                    {
                        name: {
                            key: "user",
                            choice: 2,
                        },
                        route: "security-users",
                        icon: "user",
                    },
                ],
            },
            {
                identifier: "addons",
                name: {
                    key: "addon",
                    choice: 2,
                },
                collapsed: true,
                items: [
                    {
                        name: {
                            key: "overview",
                            choice: 1,
                        },
                        route: "addons-overview",
                        icon: "puzzle-piece",
                    },
                    {
                        name: {
                            key: "suite",
                            choice: 2,
                        },
                        route: "addons-suites",
                        icon: "sitemap",
                    },
                    {
                        name: {
                            key: "linked-service",
                            choice: 2,
                        },
                        route: "addons-linked-services",
                        icon: "link",
                    },
                    {
                        name: {
                            key: "professional-service",
                            choice: 2,
                        },
                        route: "addons-professional-services",
                        icon: "hands-helping",
                    },
                    {
                        name: {
                            key: "device",
                            choice: 2,
                        },
                        route: "addons-devices",
                        icon: "tablet-alt",
                    },
                    {
                        name: {
                            key: "application",
                            choice: 2,
                        },
                        route: "addons-applications",
                        icon: "window-maximize",
                    },
                ],
            },
        ],
    }),
    actions: {
        toggle() {
            this.collapsed = !this.collapsed;
        },
        show() {
            this.collapsed = true;
        },
        collapse() {
            this.collapsed = false;
        },
        toggleGroup(identifier) {
            const group = this.content.filter((sidebarItem) => {
                return sidebarItem.identifier === identifier;
            });
            if (group && group.length === 1) {
                group[0].collapsed = !group[0].collapsed;
            }
        },
    },
});
