import { getProductRouteByCategory } from '@/lib/get-product-route';
import { useProductStore } from '@/store/modules/service/product.module';
import { computed } from 'vue';
import { onBeforeRouteUpdate } from 'vue-router';
import { useFavoritesStore } from '@/store/modules/service/favorites.module';
import { serviceTypeNames } from '@/lib/service';
import { useUserStore } from '@/store/modules/service/user.module';
export const useShop = () => {
    const productStore = useProductStore();
    const favouriteStore = useFavoritesStore();
    const userStore = useUserStore();
    const productPrice = computed(() => productStore.getProductPrice);
    const onAddToWishlist = async (product) => {
        let shortlistItem;
        const listRes = await favouriteStore.LIST({
            filter: {
                'meta_owner_account.gen_account_unique_id': userStore.profile?.accountUniqueId,
            },
            order: {},
            page: 1,
            itemsPerPage: 50,
            limit: null,
        }, 'SHORTLIST_LIST');
        const foundShortlist = listRes.list.find((item) => item.shortlistType === 'SHORTLIST_FAVORITES');
        const listItemRes = await favouriteStore.LIST_ITEM({
            filter: {},
            order: {},
            page: 1,
            itemsPerPage: 50,
            limit: null,
        });
        const foundShortListItem = listItemRes.list.find((item) => item.productUniqueId === product.productUniqueId);
        if (foundShortListItem) {
            await favouriteStore.DELETE_FAVORITE_ITEM({ shortlistItemUniqueId: foundShortListItem.shortlistItemUniqueId }, 'SHORTLIST_ITEM_DELETE');
            shortlistItem = 'remove';
        }
        else {
            await favouriteStore.ADD_FAVORITES_ITEM({
                shortlistUniqueId: foundShortlist.shortlistUniqueId,
                productUniqueId: product.productUniqueId,
                mediaCollectionItemUniqueId: null,
                shortlistItemDescShort: product.productDescShort,
                shortlistItemDescLong: null,
                shortlistItemComment: null,
                shortlistItemType: 'SHORTLIST_ITEM_PRODUCT',
                shortlistItemStatus: 'SHORTLIST_ITEM_ACTIVE',
            }, serviceTypeNames.SHORTLIST_ITEM_CREATE, 'add');
            shortlistItem = 'add';
        }
        productStore.toogleProductWishList(product.productUniqueId, shortlistItem);
    };
    const product = computed(() => productStore.product);
    onBeforeRouteUpdate(async (to, from) => {
        const product = await productStore.setProduct(to.params.uuid);
        if (product && product.productDescShort) {
            document.title = product.productDescShort;
        }
    });
    const likeProducts = computed(() => {
        return productStore.getLikeProductsWithPrice.map((_product) => {
            return {
                ..._product,
                route: getProductRouteByCategory(_product),
            };
        });
    });
    return {
        productPrice,
        product,
        onAddToWishlist,
        likeProducts,
        products: productStore.allProducts,
    };
};
