import citiesData from "@/assets/json/cities.json";
import countriesWithTz from "@/assets/json/countries-with-tz.json";
const getCountryFromCity = (city) => {
    if (city) {
        const cityToFind = city.toLowerCase();
        const country = citiesData.GB.find((item) => item.city.toLowerCase() === cityToFind)?.country;
        return country;
    }
    return undefined;
};
export const getUserLocationData = () => {
    let userRegion = "";
    let userCity = "";
    let userTimeZone = "";
    if (Intl) {
        userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const countryInfo = countriesWithTz.find((country) => country.timezones.indexOf(userTimeZone) > -1);
        const tzArr = userTimeZone.split("/");
        userRegion = tzArr[0];
        userCity = tzArr[tzArr.length - 1];
        const response = {
            city: userCity,
            country: countryInfo?.country,
            region: userRegion,
            timezone: userTimeZone,
        };
        return response;
    }
    return undefined;
};
// AMERICA --> USD, UNITED KINGDOM --> GBP, EUROPE --> EUR, everything else --> EUR
export const getRegionCurrency = (region) => {
    const userLocationData = getUserLocationData();
    const userRegion = region || getUserLocationData()?.region;
    const userCountry = getCountryFromCity(userLocationData?.city);
    if (userRegion) {
        switch (
        // region
        //   ? userRegion
        //   : userCountry === "United Kingdom"
        //   ? userCountry
        //   : userRegion
        userRegion) {
            case "America":
                return {
                    name: "USD",
                    symbol: "$",
                };
            case "Europe":
                return {
                    name: "EUR",
                    symbol: "€",
                };
            case "Australia":
                return {
                    name: "USD",
                    symbol: "$",
                };
            default:
                return {
                    name: "EUR",
                    symbol: "€",
                };
        }
    }
    return {
        name: "EUR",
        symbol: "€",
    };
};
//temporary
export const getRegionTax = (region) => {
    const userLocationData = getUserLocationData();
    const userRegion = region || getUserLocationData()?.region;
    const userCountry = getCountryFromCity(userLocationData?.city);
    const userLoggedIn = false; // --> change later on user store
    if (userRegion && !userLoggedIn) {
        switch (
        // region
        //   ? userRegion
        //   : userCountry === "United Kingdom"
        //   ? userCountry
        //   : userRegion
        userRegion) {
            case "Europe": // --> 19% if the user is not logged in
                return {
                    value: 19,
                    percentage: "19%",
                    region: userRegion,
                };
            // case "United Kingdom": // not in the EU also
            //   return {
            //     value: 0,
            //     percentage: "0%",
            //     region: userRegion,
            //   };
            default: // --> outside of EU 0% tax
                return {
                    value: 0,
                    percentage: "0%",
                    region: userRegion,
                };
        }
    }
    return {
        value: 0,
        percentage: "0%",
        region: "UNDEFINED",
    };
};
