// Composition API
import { loadScript, unloadScript } from "vue-plugin-load-script";
export const useZendesk = (key, show) => {
    if (show) {
        loadScript(`https://v2.zopim.com/?${key}`)
            .then(() => {
            /* eslint-disable */
            //@ts-nocheck
            // window.zESettings = {
            //   webWidget: {
            //     chat: {
            //       departments: {
            //         enabled: ['finance', 'hr', 'sales']
            //       }
            //     }
            //   }
            // };
        })
            .catch((err) => {
            // Failed to fetch script
            console.log('error', err);
        });
    }
    if (!show) {
        unloadScript(`https://v2.zopim.com/?${key}`)
            .then(() => {
            // Script was unloaded successfully
        })
            .catch((err) => {
            console.log('error', err);
        });
    }
};
