import { getProductPropertyValue } from './get-product-colors';
import { propertyName } from './get-product-filter';
import { getProductPriceObject } from './get-product-price';
export var sortOptions;
(function (sortOptions) {
    sortOptions["POPULARITY"] = "popularity";
    sortOptions["ORDER_ASC"] = "orderAsc";
    sortOptions["ORDER_DESC"] = "orderDesc";
    sortOptions["PRICE_HIGH"] = "priceHigh";
    sortOptions["PRICE_LOW"] = "priceLow";
    sortOptions["DEFAULT"] = "default";
})(sortOptions || (sortOptions = {}));
//HELPER FUNCTIONS
export const getProductCategory = (productGroups) => {
    if (productGroups.length > 0) {
        return productGroups[0].productGroupDescShort;
    }
};
//SORT BY POPULARITY --> by popularity property value
const SORT_BY_POPULARITY = (products) => {
    const productsCopy = [...products];
    const sortedProducts = productsCopy.sort((a, b) => {
        const aPopularity = getProductPropertyValue(propertyName.POPULARITY, a.productProperties);
        const bPopularity = getProductPropertyValue(propertyName.POPULARITY, b.productProperties);
        // console.log("aPopularity", aPopularity);
        // console.log("bPopularity", bPopularity);
        if (aPopularity > bPopularity) {
            return -1;
        }
        if (aPopularity < bPopularity) {
            return 1;
        }
        return 0;
    });
    console.log('sortedProducts', sortedProducts);
    return sortedProducts;
};
//SORT ALPHABETICALLY ASCENDING --> by productGroupDescShort
const SORT_BY_A_TO_Z = (products) => {
    const reformatedProducts = products.map((product) => {
        // const aCategory = getProductCategory(a.productCategories);
        // const bCategory = getProductCategory(b.productCategories);
        const productCategory = getProductCategory(product.productCategories);
        return {
            ...product,
            productSortCategory: product.productCategories[0].productCategoryPresentationName,
        };
    });
    const sortedProducts = reformatedProducts.sort((a, b) => {
        if (a.productSortCategory < b.productSortCategory) {
            return -1;
        }
        if (a.productSortCategory > b.productSortCategory) {
            return 1;
        }
        return 0;
    });
    return sortedProducts;
};
//SORT ALPHABETICALLY DESCENDING --> by productGroupDescShort
const SORT_BY_Z_TO_A = (products) => {
    const reformatedProducts = products.map((product) => {
        // const aCategory = getProductCategory(a.productCategories);
        // const bCategory = getProductCategory(b.productCategories);
        const productCategory = getProductCategory(product.productCategories);
        return {
            ...product,
            productSortCategory: product.productCategories[0].productCategoryPresentationName,
        };
    });
    const sortedProducts = reformatedProducts.sort((a, b) => {
        if (a.productSortCategory > b.productSortCategory) {
            return -1;
        }
        if (a.productSortCategory < b.productSortCategory) {
            return 1;
        }
        return 0;
    });
    return sortedProducts;
};
//SORT BY PRICE HIGH --> by productPriceStandard or productPriceReduced
const SORT_BY_PRICE_HIGH = (products) => {
    const newProducts = products.map((product) => {
        const productPrice = getProductPriceObject(product, product.customerGroupUniqueId);
        //add new property productPrice to product
        if (!productPrice?.productPriceNet) {
            return { ...product, productPrice: null };
        }
        else {
            return { ...product, productPrice: productPrice?.productPriceNet };
        }
    });
    const sortedProducts = newProducts.sort((a, b) => {
        if (a.productPrice === null) {
            return 1;
        }
        if (b.productPrice === null) {
            return -1;
        }
        if (a.productPrice && b.productPrice) {
            return b.productPrice - a.productPrice;
        }
        return 0;
    });
    return sortedProducts;
};
//SORT BY PRICE LOW --> by productPriceStandard or productPriceReduced
const SORT_BY_PRICE_LOW = (products) => {
    const newProducts = products.map((product) => {
        const productPrice = getProductPriceObject(product, product.customerGroupUniqueId);
        //add new property productPrice to product
        if (!productPrice?.productPriceNet) {
            return { ...product, productPrice: null };
        }
        else {
            return { ...product, productPrice: productPrice?.productPriceNet };
        }
    });
    const sortedProducts = newProducts.sort((a, b) => {
        if (a.productPrice === null) {
            return 1;
        }
        if (b.productPrice === null) {
            return -1;
        }
        if (a?.productPrice && b?.productPrice) {
            return (a.productPrice - b.productPrice);
        }
        return 0;
    });
    return sortedProducts;
};
//MAIN SORT FUNCTION
export const sortProductsBy = (sortObject) => {
    const { sortBy, products } = sortObject;
    let sortedProducts = [...products];
    if (sortObject) {
        if (sortBy === sortOptions.POPULARITY) {
            const sortedByPopularity = SORT_BY_POPULARITY(sortedProducts);
            sortedProducts = [...sortedByPopularity];
        }
        else if (sortBy === sortOptions.ORDER_ASC) {
            const sortedByOrderAsc = SORT_BY_A_TO_Z(sortedProducts);
            sortedProducts = [...sortedByOrderAsc];
        }
        else if (sortBy === sortOptions.ORDER_DESC) {
            const sortedByOrderDesc = SORT_BY_Z_TO_A(sortedProducts);
            sortedProducts = [...sortedByOrderDesc];
        }
        else if (sortBy === sortOptions.PRICE_HIGH) {
            const sortedByPriceHigh = SORT_BY_PRICE_HIGH(sortedProducts);
            sortedProducts = [...sortedByPriceHigh];
        }
        else if (sortBy === sortOptions.PRICE_LOW) {
            const sortedByPriceLow = SORT_BY_PRICE_LOW(sortedProducts);
            sortedProducts = [...sortedByPriceLow];
        }
    }
    // console.log("sortedProducts", sortedProducts);
    return sortedProducts;
};
// const sortObject = {
//   sortBy: sortOptions.POPULARITY,
//   products: products as unknown as ProductNew[],
// };
// console.log(sortProductsBy(sortObject));
