import { defineStore } from 'pinia';
export const useUiStore = defineStore('uiModule', {
    state: () => ({
        showConfirmModal: false,
        registerValuesEmail: '',
        showHideRegisterModal: false,
    }),
    actions: {
        clearStore() {
            this.showConfirmModal = false;
            this.registerValuesEmail = '';
            this.showHideRegisterModal = false;
        },
    },
});
