import { getUserLocationData } from "./get-user-region";
export var filterName;
(function (filterName) {
    filterName["FILTER_BY_COLOR"] = "FILTER_BY_COLOR";
    filterName["FILTER_BY_CATEGORY"] = "FILTER_BY_CATEGORY";
    filterName["FILTER_BY_BRAND"] = "FILTER_BY_BRAND";
    filterName["FILTER_BY_SIZE_CONTENT"] = "FILTER_BY_SIZE_CONTENT";
})(filterName || (filterName = {}));
export var propertyName;
(function (propertyName) {
    propertyName["COLOR"] = "COLOR";
    propertyName["LENGTH_IN_CM"] = "LENGTH_IN_CM";
    propertyName["CONTENT_VOLUME"] = "CONTENT_VOLUME";
    propertyName["UNITS_PER_PACK"] = "UNITS_PER_PACK";
    propertyName["POPULARITY"] = "POPULARITY";
    propertyName["DESCRIPTION"] = "productDescShort";
    propertyName["BRAND"] = "BRAND";
})(propertyName || (propertyName = {}));
export const getProductProperty = (propertyName, productProperties) => {
    return productProperties.find((productProperty) => productProperty.propertyKey === propertyName);
};
export const getProductCategory = (productGroups) => {
    return productGroups[0].productGroupDescShort;
};
const getProductBrand = (mediaObjects) => {
    const seamless1Regex = /seamless1/i;
    const peptameRegex = /peptame/i;
    if (mediaObjects[0].mediaObjectDescShort) {
        if (seamless1Regex.test(mediaObjects[0].mediaObjectDescShort)) {
            return "seamless1";
        }
        else if (peptameRegex.test(mediaObjects[0].mediaObjectDescShort)) {
            return "peptame";
        }
    }
};
// FILTER_BY_LOCATION
const FILTER_BY_LOCATION = (products) => {
    const userLocationData = getUserLocationData();
    if (!userLocationData || !userLocationData.country)
        return products;
    return products.filter((product) => {
        return product.productAvailability.excludedCountries.indexOf(userLocationData.country) < 0;
    });
};
// FILTER_BY_QUERY
const FILTER_BY_QUERY = (searchQuery, products) => {
    return products.filter((product) => product.productDescShort
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase().trim()) > -1);
};
//FILTER_BY_COLOR
const FILTER_BY_COLOR = (colorArray, products) => {
    return products.filter((product) => {
        const productColor = getProductProperty(propertyName.COLOR, product.productProperties);
        const colorArrayToLower = colorArray.map((color) => color.toLowerCase());
        if (productColor) {
            return colorArrayToLower.includes(productColor.propertyValue?.toString().toLowerCase());
        }
    });
};
//FILTER_BY_CATEGORY
const FILTER_BY_CATEGORY = (categoryArray, products) => {
    return products.filter((product) => {
        const productCategory = getProductCategory(product.productGroups).toLowerCase();
        const categoryArrayLowerCase = categoryArray.map((category) => category.cleanString().toLowerCase());
        if (productCategory) {
            return categoryArrayLowerCase.includes(productCategory.cleanString().toLowerCase());
        }
    });
};
//FILTER_BY_SIZE_CONTENT
//FILTER_BY_LENGTH
const FILTER_BY_LENGTH = (lengthArray, products) => {
    return products.filter((product) => {
        const productLength = getProductProperty(propertyName.LENGTH_IN_CM, product.productProperties);
        if (productLength) {
            return lengthArray.some((length) => {
                if (productLength.propertyValue && length.min && length.max) {
                    return (productLength.propertyValue >= length.min &&
                        productLength.propertyValue <= length.max);
                }
            });
        }
    });
};
//FILTER_BY_CONTENT
const FILTER_BY_CONTENT = (contentArray, products) => {
    return products.filter((product) => {
        const productContent = getProductProperty(propertyName.CONTENT_VOLUME, product.productProperties);
        if (productContent) {
            return contentArray.some((content) => {
                if (productContent.propertyValue && content.min && content.max) {
                    return (productContent.propertyValue >= content.min &&
                        productContent.propertyValue <= content.max);
                }
            });
        }
    });
};
//FILTER_BY_UNITS
const FILTER_BY_UNITS = (unitsArray, products) => {
    return products.filter((product) => {
        const productUnits = getProductProperty(propertyName.UNITS_PER_PACK, product.productProperties);
        if (productUnits) {
            return unitsArray.some((units) => {
                if (productUnits.propertyValue && units.min && units.max) {
                    return (productUnits.propertyValue >= units.min &&
                        productUnits.propertyValue <= units.max);
                }
            });
        }
    });
};
const FILTER_BY_SIZE_CONTENT = (sizeContentObject, products) => {
    let filteredProducts = [...products];
    if (sizeContentObject.length && sizeContentObject.length.length > 0) {
        const filteredByLength = FILTER_BY_LENGTH(sizeContentObject.length, filteredProducts);
        filteredProducts = [...filteredByLength];
    }
    if (sizeContentObject.content && sizeContentObject.content.length > 0) {
        const filteredByContent = FILTER_BY_CONTENT(sizeContentObject.content, filteredProducts);
        filteredProducts = [...filteredByContent];
    }
    if (sizeContentObject.units && sizeContentObject.units.length > 0) {
        const filteredByUnits = FILTER_BY_UNITS(sizeContentObject.units, filteredProducts);
        filteredProducts = [...filteredByUnits];
    }
    return filteredProducts;
};
//FILTER_BY_BRAND
const FILTER_BY_BRAND_OLD = (brandArray, products) => {
    return products.filter((product) => {
        const productBrand = getProductBrand(product.mediaObjects)?.toLowerCase();
        const brandArrayLowerCase = brandArray.map((brand) => brand.toLowerCase());
        if (productBrand) {
            return brandArrayLowerCase.includes(productBrand);
        }
    });
};
// const FILTER_BY_COLOR = (
//   colorArray: any[],
//   products: ProductNew[]
// ): ProductNew[] => {
//   return products.filter((product) => {
//     const productColor = getProductProperty(
//       propertyName.COLOR,
//       product.productProperties
//     );
//     const colorArrayToLower = colorArray.map((color) => color.toLowerCase());
//     if (productColor) {
//       return colorArrayToLower.includes(
//         productColor.propertyValue?.toString().toLowerCase()
//       );
//     }
//   });
// };
//FILTER_BY_BRAND
const FILTER_BY_BRAND = (brandArray, products) => {
    return products.filter((product) => {
        const productBrand = getProductProperty(propertyName.BRAND, product.productProperties);
        const brandArrayToLower = brandArray.map((color) => color.toLowerCase());
        if (productBrand) {
            return brandArrayToLower.includes(productBrand.propertyValue?.toString().toLowerCase());
        }
    });
};
//MAIN FILTER FUNCTION
export const filterProducts = (filterObject) => {
    const { filter, products } = filterObject;
    let filteredProducts = [...products];
    if (filter) {
        if (filter.filterByLocation) {
            const foundProducts = FILTER_BY_LOCATION(filteredProducts);
            filteredProducts = [...foundProducts];
        }
        if (filter.searchQuery && filter.searchQuery.length) {
            const foundProducts = FILTER_BY_QUERY(filter.searchQuery, filteredProducts);
            filteredProducts = [...foundProducts];
        }
        if (filter.color && filter.color?.length > 0) {
            const colorProducts = FILTER_BY_COLOR(filter.color, filteredProducts);
            filteredProducts = [...colorProducts];
        }
        if (filter.category && filter.category?.length > 0) {
            const categoryProducts = FILTER_BY_CATEGORY(filter.category, filteredProducts);
            filteredProducts = [...categoryProducts];
        }
        if ((filter.sizeContent?.content && filter.sizeContent.content.length > 0) ||
            (filter.sizeContent?.length && filter.sizeContent.length.length > 0) ||
            (filter.sizeContent?.units && filter.sizeContent.units.length > 0)) {
            const sizeContentProducts = FILTER_BY_SIZE_CONTENT(filter.sizeContent, filteredProducts);
            filteredProducts = [...sizeContentProducts];
        }
        if (filter.brand && filter.brand?.length > 0) {
            const brandProducts = FILTER_BY_BRAND(filter.brand, filteredProducts);
            filteredProducts = [...brandProducts];
        }
    }
    else {
        filteredProducts = [...products];
    }
    return filteredProducts;
};
// const filterObject: filterObjectProps = {
//   filter: {
//     //  color: ["Caramel", "Caviar"],
//     //  category: ["Clip-in 5 Pieces"],
//     //  sizeContent: {
//     //    length: [
//     //      {
//     //        key: "length",
//     //        min: 56,
//     //        max: 56,
//     //      },
//     //    ],
//     //     content: [
//     //       {
//     //         key: "content",
//     //         min: 10,
//     //         max: 20,
//     //       },
//     //     ],
//     //    units: [
//     //      {
//     //        key: "units",
//     //        min: 5,
//     //        max: 5,
//     //      },
//     //    ],
//     //  },
//     brand: ["Peptame"],
//   },
//   products: products as ProductNew[],
// };
// console.log(filterProducts(filterObject));
