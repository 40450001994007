export var NotificationStatus;
(function (NotificationStatus) {
    NotificationStatus["Unread"] = "NOTIFICATION_USER_REL_UNREAD";
    NotificationStatus["Read"] = "NOTIFICATION_USER_REL_READ";
    NotificationStatus["Deleted"] = "NOTIFICATION_USER_REL_DELETED";
})(NotificationStatus || (NotificationStatus = {}));
export var Context;
(function (Context) {
    Context["Webapp"] = "WEBAPP";
    Context["User"] = "USER";
    Context["Admin"] = "ADMIN";
    Context["Public"] = "PUBLIC";
    Context["Account"] = "ACCOUNT";
})(Context || (Context = {}));
export var DropdownItemType;
(function (DropdownItemType) {
    DropdownItemType["Divider"] = "DIVIDER";
    DropdownItemType["Item"] = "ITEM";
})(DropdownItemType || (DropdownItemType = {}));
export var CoreToastType;
(function (CoreToastType) {
    CoreToastType["SYSTEM_NOTIFICATION"] = "SYSTEM_NOTIFICATION";
    CoreToastType["WEBAPP_NOTIFICATION"] = "WEBAPP_NOTIFICATION";
})(CoreToastType || (CoreToastType = {}));
