import getCookie from "./get-cookie";
import { i18n } from "@/i18n";
import { getRegionCurrency } from "./get-user-region";
export const formatCurrency = (amount) => {
    let locale = i18n.locale;
    let currency = getRegionCurrency().name;
    const cookieLocale = getCookie("locale", false);
    const cookieCurrency = getCookie("currency");
    if (cookieLocale) {
        locale = cookieLocale;
    }
    if (cookieCurrency) {
        currency = cookieCurrency;
    }
    // Create our number formatter.
    const formatter = new Intl.NumberFormat(locale.value, {
        style: "currency",
        currency: currency,
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    return formatter.format(amount); /* $2,500.00 */
};
