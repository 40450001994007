/* eslint @typescript-eslint/no-var-requires: "off" */
import colorData from '@/assets/json/colors_v1.0.json';
import { cleanString } from './helper-functions';
export function getProductPropertyValue(property, productProperties) {
    //loop through productProperties and find the propertyKey matching the property and return the propertyValue
    const propertyValue = productProperties.find((item) => item.propertyKey === property)?.propertyValue;
    return propertyValue;
}
const getColorImageURL = (colorName, customColorData) => {
    const colorImageURL = customColorData.find((color) => cleanString(color.colorName) === colorName.cleanString().toLowerCase())?.colorURL;
    if (colorImageURL) {
        return {
            imageURL: colorImageURL,
            usePlaceholder: false,
        };
    }
    else {
        return {
            imageURL: 'https://via.placeholder.com/40',
            usePlaceholder: true,
        };
    }
};
//Function to get all the colors of a products with the same product category type
//need to improve later on
export function getProductColors(productCategory, productsData) {
    const productColors = {
        colors: [],
    };
    //find all products with the same productCategoryName
    if (productCategory && productsData) {
        const productsDataConverted = productsData;
        for (let i = 0; i < productsDataConverted?.length; i++) {
            if (productCategory.productCategoryName ===
                productsDataConverted[i].productCategories[0].productCategoryDescShort) {
                const colorName = getProductPropertyValue('COLOR', productsDataConverted[i].productProperties);
                const colorImageURL = getColorImageURL(colorName, colorData);
                productColors.colors.push({
                    name: getProductPropertyValue('COLOR', productsDataConverted[i].productProperties),
                    bgURL: colorImageURL.imageURL,
                    productUniqueID: productsDataConverted[i].productUniqueId,
                    selected: false,
                    usePlaceholder: colorImageURL.usePlaceholder,
                });
            }
        }
        return productColors;
    }
}
