import { defineStore } from "pinia";
import { setOptions, bootstrap } from "vue-gtag";
export const useCookieStore = defineStore("cookieModule", {
    state: () => ({
        consented: false,
        cookies: {
            functional: true,
            analytics: true,
            marketing: true,
        },
    }),
    actions: {
        setConsented(value) {
            this.consented = value;
        },
        async setCookies(cookies) {
            const { analytics } = cookies;
            this.cookies = cookies;
            // const GA_MEASUREMENT_ID =
            //   (process.env.VUE_APP_ANALYTICS_GOOGLE_ANALYTICS_GTAG_ID as string) || "G-BPFXC1K7HN";
            // setOptions({
            //   disableScriptLoad: analytics,
            //   config: {
            //     id: GA_MEASUREMENT_ID,
            //     params: {
            //       anonymize_ip: true,
            //     },
            //   },
            //   enabled: analytics,
            // });
            setOptions({
                disableScriptLoad: !analytics,
                config: {
                    id: process.env.VUE_APP_ANALYTICS_GOOGLE_ANALYTICS_GTAG_ID,
                    params: {
                        anonymize_ip: true,
                    },
                },
                // enabled: false,
                enabled: analytics,
            });
            await bootstrap();
        },
    },
    persist: {
        paths: ["consented", "cookies"],
    },
});
