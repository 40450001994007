// https://stackoverflow.com/questions/70855278/how-to-pass-reactive-data-to-a-composable-in-vue-3
// pass appliaction subject to useRegister using the above solution
import { getCredsSchema, getPasswordRepeatSchema,
// passwordRepeatSchema,
 } from '@/validations/credentials-schema';
import * as yup from 'yup';
import { REGISTER_COUNTRY_LIST, REGISTER_COUNTRY_LIST2, PHONE_REGEX, } from '@/utils/constants';
import { useGoogleRecaptcha } from './useGoogleRecaptcha';
import { AccountType, ApplicationSubject } from '@/interfaces/user-options';
import { useLoadingStore } from '@/store/modules/app/loading.module';
import { useUserStore } from '@/store/modules/service/user.module';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { inject } from 'vue';
import { useI18n } from 'vue-i18n';
export const useRegister = () => {
    const { updateShowAuthModal } = inject('authModal');
    const router = useRouter();
    const loadingStore = useLoadingStore();
    const { USER_REGISTER } = storeToRefs(loadingStore);
    const userStore = useUserStore();
    const recaptchaToken = 'USER_REGISTER';
    const { isLoading: googleRecaptchaLoading, recaptcha } = useGoogleRecaptcha();
    const { t, tm } = useI18n({ useScope: 'global' });
    const headings = {
        credentials: 'login-credentials',
        personalInfo: 'personal',
        organization: 'organization',
        billingAddress: 'billing-address',
        shippingAddress: 'shipping-address-Optional',
        contactInformation: 'contact-information',
    };
    const schemas = [
        yup.object({ applicationSubject: yup.string().required() }),
        yup.object({
            ...getCredsSchema(),
            passwordRepeat: getPasswordRepeatSchema(),
        }),
        yup.object({
            // username: yup.string().trim().required().max(50),
            firstName: yup.string().trim().max(255).required(),
            lastName: yup.string().trim().max(255).required(),
            accountType: yup.string().trim().required('validations.required-user-type'),
            phoneMobile: yup.string().nullable().matches(PHONE_REGEX, 'Phone number is not valid').trim(),
            // .matches(PHONE_REGEX_WITH_CC, {
            //   message: t('validations.phone'),
            //   excludeEmptyString: true,
            // }),
            // phonePrivate: yup
            //   .string()
            //   .trim()
            //   .matches(PHONE_REGEX_WITH_CC, {
            //     message: t('validations.phone'),
            //     excludeEmptyString: true,
            //   }),
            // phoneBusiness: yup
            //   .string()
            //   .trim()
            //   .matches(PHONE_REGEX_WITH_CC, {
            //     message: t('validations.phone'),
            //     excludeEmptyString: true,
            //   }),
            // organizationName: yup.string().required(),
            // vat: yup.string().when("accountType", {
            //   is: AccountType.Organization,
            //   then: yup.string().trim().required(),
            // }),
            organizationName: yup.string().when('accountType', {
                is: AccountType.Organization,
                then: yup.string().trim().required().max(255),
            }),
            vat: yup.string().when('accountType', {
                is: AccountType.Organization,
                then: yup.string().trim().max(255).nullable(),
            }),
        }),
        // yup.object({    street: yup.string().required()}),
        yup.object({
            addresses: yup
                .array()
                .of(yup.object().shape({
                street: yup.string().trim().required().label('street').max(255),
                city: yup.string().trim().required().label('city').max(255),
                houseNumber: yup.string().trim().nullable().label('house number').max(50),
                // additionalLine: yup.string().label("additional line"),
                postcode: yup.string().trim().required().label('postcode').max(50),
                additionalLine: yup.string().trim().label('additional line').max(255).nullable(),
                country: yup.string().trim().required().label('country').max(2),
                state: yup.string().trim().label('state').max(255).nullable(),
            }))
                .min(1)
                .strict(),
        }),
        // yup.object({
        //   // numberOfEmployees: yup.string().when('applicationSubject', {
        //   //   is: ApplicationSubject.Distributor || ApplicationSubject.Reseller,
        //   //   then: yup.string().required(),
        //   // }),
        //   numberOfEmployees: yup.string().when('applicationSubject', ([other], schema) => {
        //     console.log(other,"APPLICATION SUBJECT")
        //     return other === ApplicationSubject.Distributor ? schema.required() : schema;
        //   }),
        // }),
    ];
    const formInputs = {
        applicationSubject: [
            {
                control: 'CoreCheckboxInput',
                name: 'applicationSubject',
                label: 'application-subject',
                type: 'radio',
                inline: false,
                containerClasses: 'd-flex flex-wrap justify-content-center',
                boxTypeLabel: true,
                inputClasses: 'p-0',
                colProps: {
                    col: '12',
                },
                items: [
                    {
                        radio: {
                            label: 'pages.register.form.step1.application-subject.consumer.label',
                            value: ApplicationSubject.Individual,
                        },
                        title: 'pages.register.form.step1.application-subject.consumer.title',
                        text: 'pages.register.form.step1.application-subject.consumer.text',
                    },
                    {
                        radio: {
                            label: 'pages.register.form.step1.application-subject.professional.label',
                            value: ApplicationSubject.Retailer_professional,
                        },
                        title: 'pages.register.form.step1.application-subject.professional.title',
                        text: 'pages.register.form.step1.application-subject.professional.text',
                    },
                    {
                        radio: {
                            label: 'pages.register.form.step1.application-subject.salon.label',
                            value: ApplicationSubject.Retailer_salon,
                        },
                        title: 'pages.register.form.step1.application-subject.salon.title',
                        text: 'pages.register.form.step1.application-subject.salon.text',
                    },
                    {
                        radio: {
                            label: 'pages.register.form.step1.application-subject.sales-agent.label',
                            value: ApplicationSubject.Sales_agent,
                        },
                        title: 'pages.register.form.step1.application-subject.sales-agent.title',
                        text: 'pages.register.form.step1.application-subject.sales-agent.text',
                    },
                    {
                        radio: {
                            label: 'pages.register.form.step1.application-subject.reseller.label',
                            value: ApplicationSubject.Reseller,
                        },
                        title: 'pages.register.form.step1.application-subject.reseller.title',
                        text: 'pages.register.form.step1.application-subject.reseller.text',
                    },
                    {
                        radio: {
                            label: 'pages.register.form.step1.application-subject.distributor.label',
                            value: ApplicationSubject.Distributor,
                        },
                        title: 'pages.register.form.step1.application-subject.distributor.title',
                        text: 'pages.register.form.step1.application-subject.distributor.text',
                    },
                ],
            },
        ],
        credentials: [
            {
                control: 'CoreTextInput',
                name: 'email',
                label: 'email',
                required: true,
                colProps: {
                    col: '12',
                },
                maxChars: 50,
            },
            {
                control: 'CoreTextInput',
                name: 'password',
                label: 'password',
                required: true,
                showErrors: false,
                type: 'password',
                colProps: {
                    col: '12',
                    sm: '6',
                },
                maxChars: 50,
            },
            {
                control: 'CoreTextInput',
                type: 'password',
                name: 'passwordRepeat',
                label: 'repeat-password',
                required: true,
                showErrors: false,
                colProps: {
                    col: '12',
                    sm: '6',
                },
                maxChars: 50,
            },
            {
                control: 'CoreCheckboxInput',
                name: 'marketingInfoConsent',
                translate: true,
                // label: "marketing-info-consent",
                checkboxes: [
                    {
                        label: 'marketing-info-consent',
                        value: true,
                    },
                ],
                required: true,
                colProps: {
                    col: '12',
                },
            },
        ],
        personalInfo: [
            {
                control: 'CoreTextInput',
                name: 'firstName',
                label: 'first-name',
                required: true,
                colProps: {
                    col: '12',
                    sm: '6',
                },
                maxChars: 255,
            },
            {
                control: 'CoreTextInput',
                name: 'lastName',
                label: 'last-name',
                required: true,
                colProps: {
                    col: '12',
                    sm: '6',
                },
                maxChars: 255,
            },
            {
                control: 'CoreSelectInput',
                name: 'accountType',
                label: 'i-am',
                required: true,
                translateOptions: true,
                options: [
                    { label: 'please-select', value: '' },
                    { label: 'organization', value: AccountType.Organization },
                    { label: 'individual', value: AccountType.Individual },
                ],
                colProps: {
                    col: '12',
                    sm: '12',
                },
            },
            {
                control: 'CorePhoneNumberInput',
                name: 'phoneMobile',
                label: 'phone',
                // required: true,
                colProps: {
                    col: '12',
                    sm: '12',
                },
            },
            // {
            //   control: 'CoreTextInput',
            //   name: 'phonePrivate',
            //   label: 'phone-private',
            //   // required: true,
            //   colProps: {
            //     col: '12',
            //     sm: '6',
            //   },
            // },
            // {
            //   control: 'CoreTextInput',
            //   name: 'phoneBusiness',
            //   label: 'phone-business',
            //   // required: true,
            //   colProps: {
            //     col: '12',
            //     sm: '6',
            //   },
            // },
        ],
        organization: [
            {
                control: 'CoreTextInput',
                name: 'organizationName',
                label: 'organization-name',
                required: true,
                colProps: {
                    col: '12',
                    sm: '6',
                },
                maxChars: 255,
            },
            {
                control: 'CoreTextInput',
                name: 'vat',
                label: 'vat-number',
                required: false,
                colProps: {
                    col: '12',
                    sm: '6',
                },
                maxChars: 255,
            },
            // {
            //   control: 'CoreTextInput',
            //   name: 'phoneBusiness',
            //   label: 'phone-business',
            //   // required: true,
            //   colProps: {
            //     col: '12',
            //     sm: '6',
            //   },
            // },
        ],
        addresses: [
            {
                control: 'CoreTextInput',
                name: 'street',
                label: 'street',
                required: true,
                colProps: {
                    sm: '6',
                },
                maxChars: 255,
            },
            {
                control: 'CoreTextInput',
                name: 'houseNumber',
                label: 'house-number',
                colProps: {
                    sm: '6',
                },
                maxChars: 50,
            },
            {
                control: 'CoreTextInput',
                name: 'additionalLine',
                label: 'additional-line',
                colProps: {
                    sm: '6',
                },
                maxChars: 255,
            },
            {
                control: 'CoreTextInput',
                name: 'postcode',
                label: 'Postcode',
                required: true,
                colProps: {
                    sm: '6',
                },
                maxChars: 50,
            },
            {
                control: 'CoreTextInput',
                name: 'city',
                label: 'city',
                required: true,
                colProps: {
                    sm: '6',
                },
                maxChars: 255,
            },
            {
                control: 'CoreTextInput',
                name: 'state',
                label: 'state',
                colProps: {
                    sm: '6',
                },
                maxChars: 255,
            },
            {
                control: 'CoreSelectInput',
                name: 'country',
                label: 'country',
                required: true,
                options: REGISTER_COUNTRY_LIST,
                colProps: {
                    col: '12',
                },
                // maxChars: 2,
                translateOptions: false,
            },
            // {
            //   control: "CoreCheckboxInput",
            //   name: "showShippingAddress",
            //   colProps: {
            //     col: "12",
            //   },
            //   checkboxes: [{ label: "Alternate shipping address?", value: true }],
            // },
        ],
        // shippingAddress: [
        //   {
        //     control: "CoreTextInput",
        //     name: "shippingStreet",
        //     label: "street*",
        //     colProps: {
        //       sm: "6",
        //     },
        //   },
        //   {
        //     control: "CoreTextInput",
        //     name: "shippingHousenumber",
        //     label: "House Number*",
        //     colProps: {
        //       sm: "6",
        //     },
        //   },
        //   {
        //     control: "CoreTextInput",
        //     name: "shippingAdditionalLine",
        //     label: "Additional Line",
        //     colProps: {
        //       sm: "6",
        //     },
        //   },
        //   {
        //     control: "CoreTextInput",
        //     name: "shippingPostCode",
        //     label: "Postcode*",
        //     colProps: {
        //       sm: "6",
        //     },
        //   },
        //   {
        //     control: "CoreTextInput",
        //     name: "shippingCity",
        //     label: "City*",
        //     colProps: {
        //       sm: "6",
        //     },
        //   },
        //   {
        //     control: "CoreTextInput",
        //     name: "shippingState",
        //     label: "State",
        //     colProps: {
        //       sm: "6",
        //     },
        //   },
        // ],
        contactInformation: [
            {
                control: 'CoreTextInput',
                name: 'phoneMobile',
                label: 'phone-mobile',
                required: true,
                colProps: {
                    sm: '6',
                },
            },
        ],
        companyAndMarkets: [
            {
                name: 'numberOfEmployees',
                control: 'CoreSelectInput',
                label: 'How many employees do you have?',
                options: [
                    { label: 'please-select', value: '' },
                    {
                        label: '1-5',
                        value: '1-5',
                    },
                    {
                        label: '6-10',
                        value: '6-10',
                    },
                    {
                        label: '11-50',
                        value: '11-50',
                    },
                    {
                        label: '51+',
                        value: '51+',
                    },
                ],
                required: true,
                colProps: {
                    col: '12',
                    sm: '12',
                },
            },
            {
                name: 'numberOfSalesPerson',
                control: 'CoreSelectInput',
                label: 'How many Sales Representatives / Sales Agents do you have?',
                required: true,
                options: [
                    { label: 'please-select', value: '' },
                    {
                        label: '1-5',
                        value: '1-5',
                    },
                    {
                        label: '6-10',
                        value: '6-10',
                    },
                    {
                        label: '11-50',
                        value: '11-50',
                    },
                    {
                        label: '51+',
                        value: '51+',
                    },
                ],
                colProps: {
                    col: '12',
                    sm: '12',
                },
            },
            {
                required: true,
                name: 'companyLocations',
                control: 'CoreMultiselect',
                label: 'In which countries is your company active?',
                options: REGISTER_COUNTRY_LIST2,
                placeholder: 'Select countries',
                // trackBy: 'value',
                // optionalLabel: 'label',
                multiple: true,
                closeOnSelect: false,
                clearOnSelect: false,
                colProps: {
                    col: '12',
                },
            },
        ],
        salonAndPortfolio: [
            {
                name: 'numberOfEmployees',
                control: 'CoreSelectInput',
                label: 'pages.register.form.step5-salons.num-of-employees.label',
                options: [
                    { label: 'please-select', value: '' },
                    {
                        label: '1-5',
                        value: '1-5',
                    },
                    {
                        label: '6-10',
                        value: '6-10',
                    },
                    {
                        label: '11-50',
                        value: '11-50',
                    },
                    {
                        label: '51+',
                        value: '51+',
                    },
                ],
                colProps: {
                    col: '12',
                    sm: '12',
                },
                required: true,
            },
            {
                name: 'techniquesAndServices',
                control: 'CoreMultiselect',
                label: 'pages.register.form.step5-salons.techniques-services.label',
                options: tm('pages.register.form.step5-salons.techniques-services.options'),
                multiple: true,
                closeOnSelect: false,
                clearOnSelect: false,
                required: true,
                // trackBy: 'value',
                colProps: {
                    col: '12',
                },
            },
            {
                control: 'CoreCheckboxInput',
                type: 'radio',
                name: 'interestedInEducation',
                translate: false,
                btnTypeLabel: true,
                showCustomError: true,
                label: 'Are you interested into our online education services?',
                checkboxes: [
                    {
                        label: 'yes',
                        value: true,
                    },
                    {
                        label: 'no',
                        value: false,
                    },
                ],
                required: true,
                colProps: {
                    col: '12',
                },
            },
        ],
        portfolio: [
            {
                control: 'CoreCheckboxInput',
                type: 'radio',
                name: 'distributingBrands',
                translate: false,
                label: 'Are you already distributing hair and beauty brands?',
                btnTypeLabel: true,
                checkboxes: [
                    {
                        label: 'yes',
                        value: true,
                    },
                    {
                        label: 'no',
                        value: false,
                    },
                ],
                required: true,
                colProps: {
                    col: '12',
                },
            },
            {
                control: 'CoreTextarea',
                name: 'brands',
                label: 'brands',
                showLabel: true,
                required: true,
                colProps: {
                    col: '12',
                },
                maxChars: 4000,
            },
            {
                control: 'CoreCheckboxInput',
                type: 'radio',
                name: 'offeringEducationalServices',
                translate: true,
                label: 'Are you offering education services?',
                btnTypeLabel: true,
                checkboxes: [
                    {
                        label: 'yes',
                        value: true,
                    },
                    {
                        label: 'no',
                        value: false,
                    },
                ],
                required: true,
                colProps: {
                    col: '12',
                },
            },
            {
                name: 'educationServices',
                control: 'CoreMultiselect',
                label: 'Which education services you offer?',
                options: [
                    {
                        label: 'Academy',
                        value: 'Academy',
                    },
                    {
                        label: 'On-demand Personal Training',
                        value: 'On-demand Personal Training',
                    },
                    // {
                    //   label: 'Personal training',
                    //   value: 'personal-training',
                    // },
                    {
                        label: 'Online Education',
                        value: 'Online Education',
                    },
                    {
                        label: 'Other Education Services',
                        value: 'Other Education Services',
                    },
                ],
                // optionalLabel: 'label',
                // trackBy: 'value',
                closeOnSelect: false,
                clearOnSelect: false,
                multiple: true,
                colProps: {
                    col: '12',
                },
            },
        ],
    };
    const privacyCheckboxInput = [
        {
            label: 'I agree to the privacy policy and the general terms and conditions.',
            value: true,
        },
    ];
    const onSubmit = async (values) => {
        try {
            await recaptcha(recaptchaToken);
            // let accountType = `ACCOUNT_${registrationType}_INDIVIDUAL`;
            const authenticationMethod = {
                username: values.email,
                password: values.password,
            };
            const account = {
                customFields: [],
            };
            // PERSON
            const addresses = [];
            const billingAddress = {
                ...values.addresses[0],
                additionalLine: !values.addresses[0].additionalLine
                    ? null
                    : values.addresses[0].additionalLine,
                state: !values.addresses[0].state ? null : values.addresses[0].state,
                addressType: 'ADDRESS_BILLING',
                houseNumber: !values.addresses[0].houseNumber ? null : values.addresses[0].houseNumber,
            };
            delete billingAddress.showShippingAddress;
            addresses.push(billingAddress);
            if (values.addresses && values.addresses[1]) {
                addresses[1] = {
                    ...values.addresses[1],
                    additionalLine: !values.addresses[1].additionalLine
                        ? null
                        : values.addresses[1].additionalLine,
                    state: !values.addresses[1].state ? null : values.addresses[1].state,
                    addressType: 'ADDRESS_SHIPPING',
                    houseNumber: !values.addresses[0].houseNumber ? null : values.addresses[0].houseNumber,
                };
            }
            else {
                addresses[1] = {
                    ...values.addresses[0],
                    additionalLine: !values.addresses[0].additionalLine
                        ? null
                        : values.addresses[0].additionalLine,
                    state: !values.addresses[0].state ? null : values.addresses[0].state,
                    addressType: 'ADDRESS_SHIPPING',
                    houseNumber: !values.addresses[0].houseNumber ? null : values.addresses[0].houseNumber,
                };
            }
            const customer = {
                configurations: [
                    {
                        configurationDescShort: 'Marketing information agreement',
                        configurationPayload: {
                            CONSENT_ON_MARKETING_INFORMATION: false,
                        },
                    },
                ],
            };
            if (values.marketingInfoConsent) {
                customer.configurations[0].configurationPayload.CONSENT_ON_MARKETING_INFORMATION =
                    values.marketingInfoConsent;
                // customer = {
                //   configurations: [
                //     {
                //       configurationDescShort: 'Marketing information agreement',
                //       configurationPayload: {
                //         CONSENT_ON_MARKETING_INFORMATION: values.marketingInfoConsent,
                //       },
                //     },
                //   ],
                // };
            }
            const applicationPayload = {
                applicationSubject: values.applicationSubject,
                applicationInformation: {
                    companyAndMarkets: {},
                    portfolio: {},
                    salonAndPortfolio: {},
                },
            };
            if (values.applicationSubject === ApplicationSubject.Reseller ||
                values.applicationSubject === ApplicationSubject.Distributor) {
                applicationPayload.applicationInformation.companyAndMarkets = {
                    numberOfEmployees: values.numberOfEmployees,
                    numberOfSalesPerson: values.numberOfSalesPerson,
                    companyLocations: values.companyLocations,
                };
                applicationPayload.applicationInformation.portfolio = {
                    distributingBrands: values.distributingBrands,
                    brands: values.brands,
                    offeringEducationalServices: values.offeringEducationalServices,
                    educationServices: values.educationServices,
                };
            }
            if (values.applicationSubject === ApplicationSubject.Retailer_professional ||
                values.applicationSubject === ApplicationSubject.Retailer_salon ||
                values.applicationSubject === ApplicationSubject.Sales_agent) {
                applicationPayload.applicationInformation.salonAndPortfolio = {
                    numberOfEmployees: values.numberOfEmployees,
                    techniquesAndServices: values.techniquesAndServices,
                    interestedInEducation: values.interestedInEducation,
                };
            }
            const person = {
                salutation: null,
                titlePrefix: null,
                titleSuffix: null,
                gender: null,
                firstName: values.firstName,
                middleName: null,
                lastName: values.lastName,
                birthday: null,
                nickname: null,
                height: null,
                heightUnit: 'CM',
                weight: null,
                weightUnit: null,
                avatar: null,
                languageIso2: 'de',
                customFields: [],
                addresses: [],
            };
            const contactInformations = [
                {
                    key: 'EMAIL',
                    value: values.email,
                },
            ];
            if (values.phoneMobile) {
                contactInformations.push({
                    key: 'PHONE_MOBILE',
                    value: values.phoneMobile,
                });
            }
            if (values.phonePrivate) {
                contactInformations.push({
                    key: 'PHONE_PRIVATE',
                    value: values.phonePrivate,
                });
            }
            if (values.phoneBusiness) {
                contactInformations.push({
                    key: 'PHONE_BUSINESS',
                    value: values.phoneBusiness,
                });
            }
            let organization = null;
            if (values.accountType === AccountType.Organization && values.organizationName) {
                organization = {
                    name: values.organizationName,
                    form: null,
                    taxRegistrationNumber: null,
                    duns: null,
                    country: addresses[0].country,
                    numberOfEmployees: 0,
                    averageYearlyRevenue: 0,
                    size: 'UNDEFINED',
                    vat: values.vat ? values.vat : null,
                    customFields: [],
                    addresses,
                };
                // accountType = `ACCOUNT_${registrationType}_BUSINESS`;
            }
            else {
                person.addresses = addresses;
            }
            const payload = {
                applicationPayload,
                accountType: values.accountType,
                // applicationSubject: values.applicationSubject,
                authenticationMethod,
                account,
                person,
                organization: organization,
                contactInformations,
                customer,
            };
            if (values.applicationSubject === ApplicationSubject.Individual &&
                values.accountType === AccountType.Organization) {
                payload.applicationPayload.applicationSubject = ApplicationSubject.Business;
            }
            await userStore.REGISTER(payload);
            updateShowAuthModal(false);
            router.push('/post-registration-info');
        }
        catch (error) {
            console.log(error);
        }
    };
    return {
        headings,
        schemas,
        formInputs,
        privacyCheckboxInput,
        onSubmit,
        isLoading: USER_REGISTER || googleRecaptchaLoading,
    };
};
