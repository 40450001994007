const layoutPositionIDs = ['aa', 'ab', 'ac', 'ad', 'ae'];
function imageLayoutParser(layoutPositionID, imageName) {
    //create regex to match the layoutPositionID followed by three numbers (e.g. aa001) and then cdq2 (e.g. aa001cdq2) this is an example imageName peptame-marketing-peptame-flyers-20-14e7fe761f246e7118ef2836f1baf326-aa001-cdq2.webp (aa is the layoutPositionID)
    const regex = new RegExp(`-${layoutPositionID}\\d{3}-cdq2`, 'gm');
    // const regex = new RegExp(`-${layoutPositionID}`, 'gm');
    const result = regex.test(imageName);
    return result;
}
export function getImageLayoutPositionName(imageName) {
    let result = undefined;
    layoutPositionIDs.forEach((layoutPositionID) => {
        if (imageLayoutParser(layoutPositionID, imageName)) {
            switch (layoutPositionID) {
                case 'aa':
                    result = 'IMAGE_PRODUCT_LANDING_PAGE_260';
                    break;
                case 'ab':
                    result = 'IMAGE_PRODUCT_COLOR_45';
                    break;
                case 'ac':
                    result = 'IMAGE_PRODUCT_PAGE_MAIN_585';
                    break;
                case 'ad':
                    result = 'IMAGE_PRODUCT_PAGE_SD_80';
                    break;
                case 'ae':
                    result = 'IMAGE_PRODUCT_PAGE_FS_1080';
                    break;
                default:
                    result = undefined;
            }
        }
    });
    return result;
}
export function placeholderGenerator(size, fileFormat) {
    const imgURL = `https://via.placeholder.com/${size}/373f50.${fileFormat ? fileFormat : 'webp'}/FFFFFF?text=${size}x${size}`;
    return imgURL;
}
function getImageLayoutPositionNumber(imageName) {
    const slice = imageName.slice(-10);
    const result = slice.slice(4, 5);
    //convert string to number
    return parseInt(result);
}
export function getImageLayoutDetails(imageName) {
    const imageLayoutPositionName = getImageLayoutPositionName(imageName);
    if (imageLayoutPositionName !== undefined) {
        const imageLayoutPositionNumber = getImageLayoutPositionNumber(imageName);
        if (imageLayoutPositionNumber) {
            return {
                imageLayoutPositionName,
                imageLayoutPositionNumber,
            };
        }
    }
    return undefined;
}
export const checkImage = (getImage, imagePositionName, returnNumber) => {
    if (getImage.mediaObjectDescShort) {
        const imageLayout = getImageLayoutDetails(getImage.mediaObjectDescShort);
        if (imageLayout?.imageLayoutPositionName === imagePositionName &&
            imageLayout?.imageLayoutPositionName != undefined && imageLayout.imageLayoutPositionNumber === 1) {
            return true;
        }
        else if (imageLayout?.imageLayoutPositionName === undefined) {
            return false;
        }
    }
};
export function getImage(images, imagePositionName) {
    let result = undefined;
    images.forEach((image) => {
        if (getLayoutImage(image, imagePositionName)) {
            result = image;
        }
    });
    return result;
}
function getLayoutImage(image, imagePositionName) {
    if (image.mediaObjectDescShort && image.mediaObjectPublicUri && image.mediaObjectPrivateUri) {
        const imageLayout = getImageLayoutDetails(image.mediaObjectDescShort);
        if (imageLayout?.imageLayoutPositionName === imagePositionName &&
            imageLayout?.imageLayoutPositionName != undefined) {
            try {
                return true;
            }
            catch (error) {
                console.warn(`Image '${image.mediaObjectPublicUri}' not found, placeholder image used instead. Path: '@/assets/img/toptellers/shop/products/${image.mediaObjectPublicUri}'`);
                return false;
            }
        }
        else if (imageLayout?.imageLayoutPositionName === undefined) {
            return false;
        }
    }
    return false;
}
export function getHomepageProductImage(images) {
    for (const image of images) {
        console.log(image.mediaObjectPublicUri);
        if (checkImage(image, 'IMAGE_PRODUCT_LANDING_PAGE_260') === true) {
            console.log('found:', image.mediaObjectPublicUri);
            break;
        }
    }
}
export function getGalleryLayout(galleryImages) {
    const galleryLayout = {
        productImagesSidebar: [],
        productImagesMain: [],
        productImagesFS: [],
    };
    if (galleryImages) {
        for (let i = 0; i < galleryImages?.length; i++) {
            if (getLayoutImage(galleryImages[i], 'IMAGE_PRODUCT_PAGE_MAIN_585') === true) {
                galleryLayout.productImagesMain.push({
                    id: getImageLayoutPositionNumber(galleryImages[i].mediaObjectDescShort),
                    img: galleryImages[i].mediaObjectPublicUri,
                    fallbackImg: placeholderGenerator(585, 'webp'),
                    usePlaceholder: false,
                });
            }
            else if (getLayoutImage(galleryImages[i], 'IMAGE_PRODUCT_PAGE_SD_80') === true) {
                galleryLayout.productImagesSidebar.push({
                    id: getImageLayoutPositionNumber(galleryImages[i].mediaObjectDescShort),
                    img: galleryImages[i].mediaObjectPublicUri,
                    fallbackImg: placeholderGenerator(80, 'webp'),
                    usePlaceholder: false,
                });
            }
            else if (getLayoutImage(galleryImages[i], 'IMAGE_PRODUCT_PAGE_FS_1080') === true) {
                galleryLayout.productImagesFS.push({
                    id: getImageLayoutPositionNumber(galleryImages[i].mediaObjectDescShort),
                    img: galleryImages[i].mediaObjectPublicUri,
                    fallbackImg: placeholderGenerator(1080, 'webp'),
                    usePlaceholder: false,
                });
            }
        }
    }
    return galleryLayout;
}
