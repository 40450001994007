import { defineStore } from "pinia";
import setCookie from "@/lib/set-cookie";
export const useTableStore = defineStore("tableModule", {
    state: () => {
        return {
            tables: {
                notifications: null,
                tasks: null,
                applications: null,
                ondemand: null,
                live: null,
                interactive: null,
                collections: null,
                participants: null,
                courses: null,
                classes: null,
                educationPlans: null,
                merchants: null,
                customer: null,
                customerGroups: null,
                salesorder: null,
                subscription: null,
                orderVouchers: null,
                salesinvoice: null,
                returns: null,
                payments: null,
                catalogs: null,
                productGroups: null,
                physicalProducts: null,
                digitalProducts: null,
                servicePlans: null,
                professionalServices: null,
                productVouchers: null,
                purchasesVouchers: null,
                priceList: null,
                coupons: null,
                contacts: null,
                segments: null,
                campaigns: null,
                deliveryNotes: null,
                packingList: null,
                pickingList: null,
                shipping: null,
                stockReceipt: null,
                projectList: null,
                workItems: null,
                sprints: null,
                repo: null,
                branches: null,
                pullRequests: null,
                testCases: null,
                testRuns: null,
                roadmap: null,
                deployments: null,
                releases: null,
                events: null,
                rules: null,
                workflows: null,
                processSchedules: null,
                processJobs: null,
                automationQueues: null,
                domains: null,
                documentStorages: null,
                fileStorages: null,
                keyValueStorages: null,
                queueStorages: null,
                relationalDatabases: null,
                webApplications: null,
                mediaLibrary: null,
                securityAccounts: null,
                settingAccounts: null,
                serviceProviders: null,
                services: null,
                users: null,
                roles: null,
                permissions: null,
                identifier: null,
                logging: null,
                channels: null,
                devices: null,
                addons: null,
                creditPack: null,
                productProfessionalServices: null,
                billingOrders: null,
                billingReturns: null,
                shortlist: null,
                purchaseTemplates: null,
            },
            tableFilters: {
                notifications: null,
                tasks: null,
                applications: null,
                ondemand: null,
                live: null,
                interactive: null,
                collections: null,
                participants: null,
                courses: null,
                classes: null,
                educationPlans: null,
                merchants: null,
                customer: null,
                customerGroups: null,
                salesorder: null,
                subscription: null,
                orderVouchers: null,
                salesinvoice: null,
                returns: null,
                payments: null,
                catalogs: null,
                productGroups: null,
                physicalProducts: null,
                digitalProducts: null,
                servicePlans: null,
                professionalServices: null,
                productVouchers: null,
                purchasesVouchers: null,
                priceList: null,
                coupons: null,
                contacts: null,
                segments: null,
                campaigns: null,
                deliveryNotes: null,
                packingList: null,
                pickingList: null,
                shipping: null,
                stockReceipt: null,
                projectList: null,
                workItems: null,
                sprints: null,
                repo: null,
                branches: null,
                pullRequests: null,
                testCases: null,
                testRuns: null,
                roadmap: null,
                deployments: null,
                releases: null,
                events: null,
                rules: null,
                workflows: null,
                processSchedules: null,
                processJobs: null,
                automationQueues: null,
                domains: null,
                documentStorages: null,
                fileStorages: null,
                keyValueStorages: null,
                queueStorages: null,
                relationalDatabases: null,
                webApplications: null,
                mediaLibrary: null,
                securityAccounts: null,
                settingAccounts: null,
                serviceProviders: null,
                services: null,
                users: null,
                roles: null,
                permissions: null,
                identifier: null,
                logging: null,
                channels: null,
                devices: null,
                addons: null,
                creditPack: null,
                productProfessionalServices: null,
                billingOrders: null,
                billingReturns: null,
                shortlist: null,
                purchaseTemplates: null,
            }
        };
    },
    actions: {
        saveSettings(type, data) {
            setCookie("tables", Object.keys(this.tables), {});
            data.search = {};
            const tables = this.tables;
            tables[type] = data;
        },
        saveFilters(type, data) {
            const tableFilters = this.tableFilters;
            tableFilters[type] = data;
        },
        clear() {
            this.tables = {
                notifications: null,
                tasks: null,
                applications: null,
                ondemand: null,
                live: null,
                interactive: null,
                collections: null,
                participants: null,
                courses: null,
                classes: null,
                educationPlans: null,
                merchants: null,
                customer: null,
                customerGroups: null,
                salesorder: null,
                subscription: null,
                orderVouchers: null,
                salesinvoice: null,
                returns: null,
                payments: null,
                catalogs: null,
                productGroups: null,
                physicalProducts: null,
                digitalProducts: null,
                servicePlans: null,
                professionalServices: null,
                productVouchers: null,
                purchasesVouchers: null,
                priceList: null,
                coupons: null,
                contacts: null,
                segments: null,
                campaigns: null,
                deliveryNotes: null,
                packingList: null,
                pickingList: null,
                shipping: null,
                stockReceipt: null,
                projectList: null,
                workItems: null,
                sprints: null,
                repo: null,
                branches: null,
                pullRequests: null,
                testCases: null,
                testRuns: null,
                roadmap: null,
                deployments: null,
                releases: null,
                events: null,
                rules: null,
                workflows: null,
                processSchedules: null,
                processJobs: null,
                automationQueues: null,
                domains: null,
                documentStorages: null,
                fileStorages: null,
                keyValueStorages: null,
                queueStorages: null,
                relationalDatabases: null,
                webApplications: null,
                mediaLibrary: null,
                securityAccounts: null,
                settingAccounts: null,
                serviceProviders: null,
                services: null,
                users: null,
                roles: null,
                permissions: null,
                identifier: null,
                logging: null,
                channels: null,
                devices: null,
                addons: null,
                creditPack: null,
                productProfessionalServices: null,
                billingOrders: null,
                billingReturns: null,
                shortlist: null,
                purchaseTemplates: null
            };
            this.tableFilters = {
                notifications: null,
                tasks: null,
                applications: null,
                ondemand: null,
                live: null,
                interactive: null,
                collections: null,
                participants: null,
                courses: null,
                classes: null,
                educationPlans: null,
                merchants: null,
                customer: null,
                customerGroups: null,
                salesorder: null,
                subscription: null,
                orderVouchers: null,
                salesinvoice: null,
                returns: null,
                payments: null,
                catalogs: null,
                productGroups: null,
                physicalProducts: null,
                digitalProducts: null,
                servicePlans: null,
                professionalServices: null,
                productVouchers: null,
                purchasesVouchers: null,
                priceList: null,
                coupons: null,
                contacts: null,
                segments: null,
                campaigns: null,
                deliveryNotes: null,
                packingList: null,
                pickingList: null,
                shipping: null,
                stockReceipt: null,
                projectList: null,
                workItems: null,
                sprints: null,
                repo: null,
                branches: null,
                pullRequests: null,
                testCases: null,
                testRuns: null,
                roadmap: null,
                deployments: null,
                releases: null,
                events: null,
                rules: null,
                workflows: null,
                processSchedules: null,
                processJobs: null,
                automationQueues: null,
                domains: null,
                documentStorages: null,
                fileStorages: null,
                keyValueStorages: null,
                queueStorages: null,
                relationalDatabases: null,
                webApplications: null,
                mediaLibrary: null,
                securityAccounts: null,
                settingAccounts: null,
                serviceProviders: null,
                services: null,
                users: null,
                roles: null,
                permissions: null,
                identifier: null,
                logging: null,
                channels: null,
                devices: null,
                addons: null,
                creditPack: null,
                productProfessionalServices: null,
                billingOrders: null,
                billingReturns: null,
                shortlist: null,
                purchaseTemplates: null
            };
        }
    },
    persist: {
        paths: ["tables", "tableFilters"],
    },
});
