import { Context } from '@/interfaces/application';
import { sendRequest } from '@/lib/request';
import { serviceTypeNames } from '@/lib/service';
import { defineStore } from 'pinia';
import { useCartStore } from './cart.module';
import { loadingNames } from '../app/loading.module';
import { useToastStore } from '../app/toast.module';
import { i18n } from '@/i18n';
export const usePaymentStore = defineStore('paymentModule', {
    state: () => ({
        // paymentForms: {
        //   total: 0,
        //   gatewayList: [],
        // },
        signature: null,
        sessionId: null,
        paymentForms: [],
        methods: {
            total: null,
            paymentMethodList: [],
        },
        salesOrderUniqueId: '',
        savePaymentMethod: false,
        paymentFormData: null,
        url: '',
        paymentFormUniqueId: null,
        selectedPaymentForm: null,
        paymentMethodUniqueId: null,
        lastPaymentMethodUsed: '',
        purchaseSuccessful: false,
        selectedPaymentMethod: { method: '', remarks: '', charges: 0 },
        paymentUniqueId: null,
    }),
    actions: {
        SET_SELECTED_PAYMENT_FORM(id) {
            if (id) {
                const paymentForm = this.paymentForms.find((item) => {
                    return item.paymentFormUniqueId == id;
                });
                if (paymentForm) {
                    this.selectedPaymentForm = paymentForm;
                }
            }
            else {
                this.selectedPaymentForm = null;
            }
        },
        SET_SELECTED_PAYMENT_METHOD(method) {
            this.selectedPaymentMethod = method;
        },
        CLEAR_PAYMENT_STORE() {
            this.paymentUniqueId = null;
            this.paymentForms = [];
            this.methods.paymentMethodList = [];
            this.salesOrderUniqueId = '';
            this.paymentFormUniqueId = null;
            this.paymentFormData = null;
            this.url = '';
            this.lastPaymentMethodUsed = this.paymentMethodUniqueId;
            this.paymentMethodUniqueId = null;
            this.savePaymentMethod = false;
            this.purchaseSuccessful = false;
            this.selectedPaymentMethod = { method: '', remarks: '', charges: 0 };
        },
        async MAKE_PAYMENT(savePaymentMethod = true, baseURL = 'https://localhost:8080') {
            const cartStore = useCartStore();
            if (!this.methods.paymentMethodList.length) {
                await this.PAYMENT_METHOD_LIST({
                    filter: {},
                    order: {},
                    page: 1,
                    itemsPerPage: 5,
                    limit: null,
                    search: {},
                });
            }
            if (!this.paymentForms.length) {
                // await this.PAYMENT_GATEWAY_LIST({
                //   filter: {},
                //   order: {},
                //   page: 1,
                //   itemsPerPage: 5,
                //   limit: null,
                // });
            }
            const paymentFormUniqueId = this.paymentFormUniqueId;
            // if (!this.checkoutId) {
            const PaymentStartResponse = await this.PAYMENT_START({
                salesOrderUniqueId: null,
                cartUniqueId: cartStore.cartUniqueId,
                paymentMethodUniqueId: null,
                paymentMethodSave: true,
                paymentFormUniqueId: paymentFormUniqueId,
                paymentFormData: {
                    // paymentMethodSave: true,
                    returnUrl: `${baseURL}/checkout/loading`,
                    customerIp: '127.1.1.1',
                    shopUrl: baseURL,
                },
            });
            this.paymentFormData = PaymentStartResponse?.paymentFormData;
            this.url = PaymentStartResponse?.paymentFormData.url;
            this.paymentUniqueId = PaymentStartResponse?.payment?.paymentUniqueId;
            // }
            const returnResponse = {
                paymentForms: this.paymentForms,
                salesOrderUniqueId: cartStore.salesOrderUniqueId,
                paymentFormUniqueId: this.paymentFormUniqueId,
                paymentFormData: this.paymentFormData,
                url: this.url,
                paymentMethodUniqueId: this.paymentMethodUniqueId,
            };
            return returnResponse;
        },
        async PAYMENT_FORM_LIST(payload) {
            try {
                const res = await sendRequest(serviceTypeNames.SCENARIO_SG01_X00002, payload, loadingNames.SALES_INFORMATON, '', Context.Account);
                this.paymentForms = res.paymentForms;
                return res;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async PAYMENT_GATEWAY_GET(payload) {
            try {
                const res = await sendRequest(serviceTypeNames.PAYMENT_GATEWAY_GET, payload);
                return res;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async PAYMENT_METHOD_LIST(payload) {
            try {
                const res = await sendRequest(serviceTypeNames.PAYMENT_METHOD_LIST, payload);
                this.methods.paymentMethodList = res.list;
                this.methods.total = res.total;
                return res;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async PAYMENT_METHOD_GET(payload) {
            try {
                const res = await sendRequest(serviceTypeNames.PAYMENT_METHOD_GET, payload);
                return res;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async PAYMENT_METHOD_CREATE(payload) {
            try {
                const res = await sendRequest(serviceTypeNames.PAYMENT_METHOD_CREATE, payload, undefined, 'Payment method added successfully');
                return res;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async PAYMENT_METHOD_SET_DEFAULT(payload) {
            try {
                const res = await sendRequest(serviceTypeNames.PAYMENT_METHOD_SET_DEFAULT, payload, undefined, 'Default payment method changed successfully');
                return res;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async PAYMENT_INIT(baseURL = 'https://localhost:8080') {
            const payload = {
                paymentFormUniqueId: this.paymentFormUniqueId,
                paymentFormData: {
                    paymentMethodSave: true,
                    returnUrl: `${baseURL}/checkout/loading`,
                    customerIp: '127.1.1.1',
                    shopUrl: baseURL,
                },
            };
            try {
                const res = await sendRequest(serviceTypeNames.SCENARIO_SG10_X00029, payload);
                console.log(res);
                this.paymentFormUniqueId = res.paymentFormUniqueId;
                this.paymentFormData = res.paymentFormData;
                this.url = res.paymentFormData.url;
                return res.paymentFormData;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async PAYMENT_START(payload) {
            try {
                const res = await sendRequest(serviceTypeNames.SCENARIO_SG10_X00030, payload);
                if (res.paymentFormData.session_id) {
                    this.sessionId = res.paymentFormData.session_id;
                }
                if (res.paymentFormData.signature) {
                    this.signature = res.paymentFormData.signature;
                }
                return res;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async PAYMENT_CONFIRM(payload) {
            const cartStore = useCartStore();
            payload.paymentFormData = {
                ...payload.paymentFormData,
                session_id: this.sessionId,
                signature: this.signature,
                // exp_month: '04',
                // exp_year: '2024',
                url: process.env.NODE_ENV === 'production'
                    ? 'https://payabl.com'
                    : 'https://sandbox.payabl.com',
                // cardholder_name: 'John Doe',
            };
            try {
                const res = await sendRequest(serviceTypeNames.SCENARIO_SG10_X00032, payload);
                cartStore.CLEAR_CART_STORE();
                this.CLEAR_PAYMENT_STORE();
                return res;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async PAYMENT_CREATE(payload) {
            const toastStore = useToastStore();
            try {
                const res = await sendRequest(serviceTypeNames.SCENARIO_SG10_X00031, payload);
                if (res.paymentFormUniqueId) {
                    this.paymentFormUniqueId = res.paymentFormUniqueId;
                }
                this.salesOrderUniqueId = res.salesOrderUniqueId;
                this.purchaseSuccessful = res.purchaseSuccessful;
                this.paymentUniqueId = res.payment.paymentUniqueId;
                if (!res.purchaseSuccessful) {
                    toastStore.danger({
                        title: i18n.t('toasts.danger.title'),
                        message: i18n.t('toasts.danger.messages.payment-failed'),
                    });
                }
                return res;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
    },
    persist: {
        paths: [
            'gatewayList',
            'salesOrderUniqueId',
            'savePaymentMethod',
            'paymentFormUniqueId',
            'paymentFormData',
            'url',
            'lastPaymentMethodUsed',
            'paymentUniqueId',
        ],
    },
});
