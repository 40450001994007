/**
 *
 * @param startYear
 * @param copyrightText
 * @param includeCopyrightSymbol
 * @returns
 */
export const getFooterCopyrightText = (startYear, copyrightText, includeCopyrightSymbol) => {
    const currentYear = new Date().getFullYear();
    const includeCopySymbol = includeCopyrightSymbol || true;
    return `${includeCopySymbol && "©"} ${startYear}-${currentYear} ${copyrightText}`;
};
