import { openDB } from 'idb';
const CACHE_VERSION = 1;
const dbPromise = openDB('main', 1, {
    upgrade(db) {
        db.createObjectStore('products');
    },
});
export async function get(key) {
    return (await dbPromise).get('products', key);
}
export async function set(key, val) {
    return (await dbPromise).put('products', val, key);
}
export async function keys() {
    return (await dbPromise).getAllKeys('products');
}
export async function getAll() {
    return (await dbPromise).getAll('products');
}
export async function clear() {
    return (await dbPromise).clear('products');
}
//test
