import { ref } from 'vue';
export var MessagesType;
(function (MessagesType) {
    MessagesType["CHECKOUT_COMPLETE"] = "CHECKOUT_COMPLETE";
})(MessagesType || (MessagesType = {}));
const bc = ref();
const subscribers = ref([]);
export const useBroadcastChannel = () => {
    const registerBroadcastChannel = () => {
        if (!bc.value) {
            bc.value = new BroadcastChannel('tabs');
            bc.value.onmessage = (event) => {
                subscribers.value.forEach((cb) => cb(event));
            };
        }
        return bc.value;
    };
    const closeBroadcastChannel = () => {
        if (bc.value)
            bc.value.close();
        subscribers.value = [];
    };
    const sendMessage = (data) => {
        bc.value?.postMessage(data);
    };
    const subscribeToMessage = (callback) => {
        subscribers.value.push(callback);
        // const i = subscribers.value.length - 1
        // return () => {
        //   subscribers.value.splice(i)
        // };
    };
    return {
        sendMessage,
        registerBroadcastChannel,
        closeBroadcastChannel,
        subscribeToMessage,
    };
};
