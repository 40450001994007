export const useNavigation = (extraInfo) => {
    if (extraInfo?.nextClass && extraInfo.prevClass) {
        return {
            nextEl: ".swiper-button-next" + extraInfo.nextClass,
            prevEl: ".swiper-button-prev" + extraInfo.prevClass,
            nextClass: "swiper-button-next" + extraInfo.nextClass,
            prevClass: "swiper-button-prev" + extraInfo.nextClass,
            enabled: true,
            navigationDisabledClass: "disable-navigation",
        };
    }
    else {
        return {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            nextClass: "swiper-button-next",
            prevClass: "swiper-button-prev",
            enabled: true,
            navigationDisabledClass: "disable-navigation",
        };
    }
};
