export var sizeContentKeyNames;
(function (sizeContentKeyNames) {
    sizeContentKeyNames["content"] = "content";
    sizeContentKeyNames["length"] = "length";
    sizeContentKeyNames["units"] = "units";
})(sizeContentKeyNames || (sizeContentKeyNames = {}));
export const removeCommas = (str) => {
    return str.replaceAll(",", "");
};
export const getArrayFromString = (str) => {
    const arr = str.split(",");
    return arr;
};
export const removeItemFromArray = (arr, item) => {
    const index = arr.indexOf(item);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
};
export const removeObjectFromArray = (arr, arrKey, itemKey) => {
    const index = arr[arrKey].findIndex((item) => item.key === itemKey);
    if (index > -1) {
        arr[arrKey].splice(index, 1);
    }
    return arr;
};
export const arrayToLowerCase = (arr) => {
    const newArr = arr.map((item) => item.toLowerCase());
    return newArr;
};
export const getIsProductWishlisted = (whishlist, productID) => {
    return whishlist.some((item) => item.productUniqueId === productID);
};
export const cleanString = (str) => {
    //remove all special characters and spaces from string
    return str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
};
// String.prototype.cleanString = function (AR) {
//   return this.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
// }
//add a new method to the String class to clean a string
String.prototype.cleanString = function () {
    const str = this.valueOf();
    return str.replace(/[^a-zA-Z0-9]/g, "");
};
