import { getRegionCurrency, getRegionTax } from "./get-user-region";
// const isPriceValid = (productPriceListValidFromDt: string): boolean => {
//   return true;
// }
export const calculatePriceAfterTax = (price) => {
    const regionTax = getRegionTax();
    const item_cost = price;
    const tax = regionTax.value / 100;
    const tax_price = item_cost * tax;
    const total_price = (item_cost + tax_price).toFixed(2);
    return {
        price: Number(total_price),
        percentage: regionTax.percentage,
        region: regionTax.region,
    };
};
const compareProductPrices = (productPricesStandard, productPricesReduced, productPriceListDescShort) => {
    if (productPricesStandard.length > 0 && productPricesReduced.length > 0) {
        const standardPrices = productPricesStandard.reduce((acc, curr) => {
            acc[curr.productPriceListDescShort] = curr.productPriceListNetValue;
            return acc;
        }, {});
        const reducedPrices = productPricesReduced.reduce((acc, curr) => {
            acc[curr.productPriceListDescShort] = curr.productPriceListNetValue;
            return acc;
        }, {});
        const compareKeys = Object.keys(standardPrices);
        const compareValues = compareKeys.map((key) => {
            return reducedPrices[key] < standardPrices[key];
        });
        return compareValues.every((item) => item === true);
    }
    return undefined;
};
const getPriceByListDescShort = (productPrices, productPriceListDescShort) => {
    // const price = productPrices.find(
    //   (item) => item.productPriceListDescShort === productPriceListDescShort
    // )?.productPriceListNetValue;
    const price = productPrices.length > 0
        ? productPrices[0].productPriceCurrencyNetValue
        : 0;
    if (price) {
        return price;
    }
    return 0;
};
// --> implement later on because for now the price is always the same for all the currencies
// const getPriceByCurrency = ( currency: Currency) => {}
//get price base on region currency and product price list reduced or standard based on the comparison
export const getProductPrice = (product, // change to ProductNew later
productPriceListDescShort // make it mandatory later
// userCurrency?: {
//   name: string;
//   symbol?: string;
// },
) => {
    const userCurrency = getRegionCurrency();
    const compareResult = compareProductPrices(product.productPrices.productPricesStandard, product.productPrices.productPricesReduced);
    const finalPriceObject = compareResult
        ? product.productPrices.productPricesReduced
        : product.productPrices.productPricesStandard;
    const netPrice = getPriceByListDescShort(finalPriceObject, productPriceListDescShort);
    const taxPriceObject = calculatePriceAfterTax(netPrice);
    if (userCurrency) {
        return {
            productOnSale: compareResult ? true : false,
            productPriceNet: netPrice,
            productPriceGross: taxPriceObject.price,
            productPriceStandard: Number(getPriceByListDescShort(product.productPrices.productPricesStandard, productPriceListDescShort)),
            productCurrency: userCurrency.name,
            productCurrencySymbol: userCurrency.symbol,
            productTaxRegion: taxPriceObject.region,
            productPriceListDescShort: productPriceListDescShort,
        };
    }
    return undefined;
};
const getPriceObj = (priceArr, customerGroupUniqueId, userCurrency) => {
    if (!customerGroupUniqueId)
        return null;
    const priceObj = priceArr.find((price) => {
        return price.productPriceCustomerGroupUniqueId === customerGroupUniqueId && userCurrency === price.productPriceCurrency;
    });
    return priceObj ? priceObj : null;
};
export const getProductPriceObject = (product, customerGroupUniqueId) => {
    const userCurrency = getRegionCurrency();
    const productPricesStandard = getPriceObj(product.productPrices.productPricesStandard, customerGroupUniqueId, userCurrency.name ?? 'EUR');
    const productPricesReduced = getPriceObj(product.productPrices.productPricesReduced, customerGroupUniqueId, userCurrency.name ?? 'EUR');
    const reducedPrice = productPricesReduced
        ? productPricesReduced.productPriceCurrencyNetValue
        : 0;
    const standardPrice = productPricesStandard
        ? productPricesStandard.productPriceCurrencyNetValue
        : 0;
    const productOnSale = reducedPrice < standardPrice ? true : false;
    const netPrice = productOnSale && productPricesReduced ? reducedPrice : standardPrice;
    const taxPriceObject = calculatePriceAfterTax(netPrice);
    return {
        productOnSale,
        productPriceNet: netPrice,
        productPriceGross: taxPriceObject.price,
        productPriceStandard: standardPrice,
        productCurrency: userCurrency.name,
        productCurrencySymbol: userCurrency.symbol,
        productTaxRegion: taxPriceObject.region,
        productPriceListDescShort: "CONSUMER_SALES_PRICE_LIST_EUR"
    };
};
// console.log(
//   comparePrices(prices.productPricesStandard, prices.productPricesReduced)
// );
// console.log(getProductPrice(product, "CONSUMER_SALES_PRICE_LIST_EUR"));
