<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.75 25.5C7.0625 25.5 6.47417 25.2554 5.985 24.7663C5.495 24.2763 5.25 23.6875 5.25 23C5.25 22.3125 5.495 21.7238 5.985 21.2338C6.47417 20.7446 7.0625 20.5 7.75 20.5C8.4375 20.5 9.02583 20.7446 9.515 21.2338C10.005 21.7238 10.25 22.3125 10.25 23C10.25 23.6875 10.005 24.2763 9.515 24.7663C9.02583 25.2554 8.4375 25.5 7.75 25.5ZM20.25 25.5C19.5625 25.5 18.9742 25.2554 18.485 24.7663C17.995 24.2763 17.75 23.6875 17.75 23C17.75 22.3125 17.995 21.7238 18.485 21.2338C18.9742 20.7446 19.5625 20.5 20.25 20.5C20.9375 20.5 21.5263 20.7446 22.0163 21.2338C22.5054 21.7238 22.75 22.3125 22.75 23C22.75 23.6875 22.5054 24.2763 22.0163 24.7663C21.5263 25.2554 20.9375 25.5 20.25 25.5ZM6.6875 5.5L9.6875 11.75H18.4375L21.875 5.5H6.6875ZM7.75 19.25C6.8125 19.25 6.10417 18.8383 5.625 18.015C5.14583 17.1925 5.125 16.375 5.5625 15.5625L7.25 12.5L2.75 3H1.46875C1.11458 3 0.822917 2.88 0.59375 2.64C0.364583 2.40083 0.25 2.10417 0.25 1.75C0.25 1.39583 0.37 1.09875 0.61 0.85875C0.849167 0.619583 1.14583 0.5 1.5 0.5H3.53125C3.76042 0.5 3.97917 0.5625 4.1875 0.6875C4.39583 0.8125 4.55208 0.989583 4.65625 1.21875L5.5 3H23.9375C24.5 3 24.8854 3.20833 25.0938 3.625C25.3021 4.04167 25.2917 4.47917 25.0625 4.9375L20.625 12.9375C20.3958 13.3542 20.0938 13.6771 19.7188 13.9063C19.3438 14.1354 18.9167 14.25 18.4375 14.25H9.125L7.75 16.75H21.5312C21.8854 16.75 22.1771 16.8696 22.4062 17.1088C22.6354 17.3488 22.75 17.6458 22.75 18C22.75 18.3542 22.63 18.6508 22.39 18.89C22.1508 19.13 21.8542 19.25 21.5 19.25H7.75Z"
      fill="white"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
