import Service from '@/lib/service';
import { Context } from '@/interfaces/application';
export default new (class CliffMarketService extends Service {
    /**
     * Registers a new user within the service.
     *
     * @param user
     * @constructor
     */
    USER_REGISTER(user) {
        const payload = {
            channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            ...user,
        };
        return this.request('USER_REGISTER', payload, 'PUBLIC');
    }
    USER_ACTIVATE(token) {
        const payload = {
            channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            token,
        };
        return this.request('SCENARIO_SG03_X00008', payload, 'PUBLIC');
    }
    /**
     * @param {AuthenticationMethod} authenticationMethod
     * @returns {Promise<any>}
     */
    USER_LOGIN(authenticationMethod) {
        const payload = {
            channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            authenticationMethodType: 'AUTHENTICATION_METHOD_SYSTEM_USERNAME',
            username: authenticationMethod.username,
            password: authenticationMethod.password,
        };
        return this.request('USER_LOGIN', payload, 'PUBLIC');
    }
    /**
     * @param {Object} username
     * @returns {Promise<any>}
     */
    USER_LOST_PASSWORD(username) {
        const payload = {
            channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            username,
            passwordResetUrl: process.env.VUE_APP_APPLICATION_DOMAIN + '/password-recovery',
            passwordResetExpiresInSeconds: 86400,
        };
        return this.request('USER_LOST_PASSWORD', payload, 'PUBLIC');
    }
    /**
     * @param {string} token
     * @param {string} newPassword
     * @returns {Promise<any>}
     */
    USER_RECOVER_PASSWORD(token, newPassword) {
        const payload = {
            channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            token,
            newPassword,
        };
        return this.request('USER_RECOVER_PASSWORD', payload, 'PUBLIC');
    }
    /**
     * @returns {Promise<any>}
     */
    USER_GET_PROFILE() {
        return this.request('USER_GET_PROFILE');
    }
    /**
       * @param {User} user
  
       * @returns {Promise<any>}
       */
    USER_UPDATE_PROFILE(user) {
        return this.request('USER_UPDATE_PROFILE', user);
    }
    /**
     * @param {string} username
     * @param {string} password
     * @param {string} newPassword
     * @returns {Promise<any>}
     */
    USER_UPDATE_PASSWORD(username, password, newPassword) {
        return this.request('USER_UPDATE_PASSWORD', {
            username,
            password,
            newPassword,
        });
    }
    /**
     * @param {CaptchaVerifyOptions} options
     * @returns Promise<CaptchaVerifyResponse>
     */
    CAPTCHA_VERIFY(options) {
        const payload = {
            channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            ...options,
        };
        return this.request('CAPTCHA_VERIFY', payload, 'PUBLIC');
    }
    /**
     * @param {CaptchaVerifyOptions} options
     * @returns Promise<CaptchaVerifyResponse>
     */
    VOUCHER_REDEEM(voucherCode) {
        const payload = {
            channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            voucherCode,
        };
        return this.request('VOUCHER_REDEEM', payload, 'USER');
    }
    /**
     * @param {ListServiceObjectOptions} options
     * @returns Promise<CaptchaVerifyResponse>
     */
    VOUCHER_LIST(payload) {
        return this.request('VOUCHER_LIST', payload, 'USER');
    }
    /**
     * @param {CaptchaVerifyOptions} options
     * @returns Promise<CaptchaVerifyResponse>
     */
    MEDIA_OBJECT_LIST(options, mediaType) {
        const payload = {
            ...options,
        };
        return this.request(mediaType, payload, 'USER');
    }
    /**
     * @param {CaptchaVerifyOptions} options
     * @returns Promise<CaptchaVerifyResponse>
     */
    MEDIA_OBJECT_UPDATE(options, mediaType) {
        const payload = {
            ...options,
        };
        return this.request(mediaType, payload, 'USER');
    }
    USERS_LIST(options, context) {
        return this.request('USER_LIST', options, context);
    }
    USERS_ADD(payload, context = 'USER', editMode = false) {
        const requestType = editMode ? 'USER_UPDATE_PROFILE' : 'USER_CREATE';
        const options = {
            channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            ...payload,
        };
        return this.request(requestType, options, context);
    }
    USERS_REMOVE(userUniqueId, context = 'USER') {
        const options = {
            channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            userUniqueId,
        };
        return this.request('USER_DELETE', options, context);
    }
    // cart
    CART_ITEM_ADD(payload, context = 'USER') {
        const options = {
            channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            ...payload,
        };
        return this.request('CART_ITEM_ADD', options, context);
    }
    CART_ITEM_REORDER(payload, context = Context.Account) {
        const options = {
            channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            ...payload,
        };
        return this.request('CART_ITEM_ADD', options, context);
    }
    CART_OBTAIN(payload, context = 'USER') {
        const options = {
            channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            ...payload,
        };
        return this.request('CART_OBTAIN', options, context);
    }
    CART_CLEAR(payload, context = 'USER') {
        const options = {
            channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            ...payload,
        };
        return this.request('CART_CLEAR', options, context);
    }
    CART_DELETE(payload, context = 'USER') {
        const options = {
            channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            ...payload,
        };
        return this.request('CART_DELETE', options, context);
    }
    CART_ITEM_UPDATE(payload, context = 'USER') {
        const options = {
            channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            ...payload,
        };
        return this.request('CART_ITEM_UPDATE', options, context);
    }
    CART_ITEM_DELETE(payload, context = 'USER') {
        const options = {
            channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            ...payload,
        };
        return this.request('CART_ITEM_DELETE', options, context);
    }
    CART_COUPON_ADD(payload, context = 'USER') {
        const options = {
            channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            ...payload,
        };
        return this.request('CART_COUPON_ADD', options, context);
    }
    CART_COUPON_DELETE(payload, context = 'USER') {
        const options = {
            channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            ...payload,
        };
        return this.request('CART_COUPON_DELETE', options, context);
    }
    CART_SALES_ORDER_CREATE(payload, context = 'USER') {
        const options = {
            channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            ...payload,
        };
        return this.request('CART_SALES_ORDER_CREATE', options, context);
    }
    OBJECT_GET(payload, type, context = 'USER') {
        const options = {
            channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            ...payload,
        };
        return this.request(type, options, context);
    }
    PAYMENT_GATEWAY_LIST(payload, context = 'USER') {
        const options = {
            // channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            ...payload,
        };
        return this.request('PAYMENT_GATEWAY_LIST', options, context);
    }
    PAYMENT_GATEWAY_GET(payload, context = 'USER') {
        const options = {
            // channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            ...payload,
        };
        return this.request('PAYMENT_GATEWAY_GET', options, context);
    }
    PAYMENT_METHOD_LIST(payload, context = 'USER') {
        const options = {
            // channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            ...payload,
        };
        return this.request('PAYMENT_METHOD_LIST', options, context);
    }
    PAYMENT_METHOD_GET(payload, context = 'USER') {
        const options = {
            // channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            ...payload,
        };
        return this.request('PAYMENT_METHOD_GET', options, context);
    }
    PAYMENT_METHOD_CREATE(payload, context = 'USER') {
        const options = {
            // channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            ...payload,
        };
        return this.request('PAYMENT_METHOD_CREATE', options, context);
    }
    PAYMENT_INIT(payload, context = 'USER') {
        const options = {
            // channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            ...payload,
        };
        return this.request('PAYMENT_INIT', options, context);
    }
    PAYMENT_START(payload, context = 'USER') {
        const options = {
            // channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            ...payload,
        };
        return this.request('PAYMENT_START', options, context);
    }
    PAYMENT_CREATE(payload, context = 'USER') {
        const options = {
            // channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            ...payload,
        };
        return this.request('PAYMENT_CREATE', options, context);
    }
    PAYMENT_CONFIRM(payload, context = 'USER') {
        const options = {
            // channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            ...payload,
        };
        return this.request('PAYMENT_CONFIRM', options, context);
    }
    ACTIVITY_LOG_LIST(payload) {
        return this.request('ACTIVITY_LOG_LIST', payload, 'USER');
    }
    COMMENTS_LIST(payload) {
        return this.request('COMMENT_LIST', payload, 'USER');
    }
    COMMENT_CREATE(payload) {
        return this.request('COMMENT_CREATE', payload, 'USER');
    }
    COMMENT_UPDATE(payload) {
        return this.request('COMMENT_UPDATE', payload, 'USER');
    }
    COMMENT_DELETE(payload) {
        return this.request('COMMENT_DELETE', { commentUniqueId: payload }, 'USER');
    }
    SHORTLIST_LIST(payload, type) {
        return this.request(type, payload, 'USER');
    }
    SHORTLIST_ITEM_LIST(payload, type) {
        return this.request(type, payload, 'USER');
    }
    SHORTLIST_ITEM_GET(payload, type) {
        return this.request(type, payload, 'USER');
    }
    FAVORITES_ADD(options, type) {
        return this.request(type, options, 'USER');
    }
    FAVORITES_ITEM_ADD(options, type) {
        return this.request(type, options, 'USER');
    }
    FAVORITES_DELETE(options, type) {
        return this.request(type, options, 'USER');
    }
    FAVORITES_ITEM_DELETE(options, type) {
        return this.request(type, options, 'USER');
    }
})();
