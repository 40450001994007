import { useField } from "vee-validate";
import { computed, ref, toRef } from "vue";
export const useInput = (props) => {
    const toggleInputRef = toRef(props, "toggleInputMode");
    const showInput = ref(toggleInputRef.value ? false : true);
    const nameRef = toRef(props, "name");
    const { errors, value, label: field, meta } = useField(nameRef, undefined, {
        label: props.label.replace("-", " "),
        standalone: props.standalone,
    });
    const getErrorArgs = computed(() => {
        if (!errors.value.length)
            return;
        const err = errors.value[0];
        return err.split("|")[1] ? JSON.parse(err.split("|")[1]) : {};
    });
    const toggleShowInput = (show) => {
        if (!toggleInputRef.value)
            return;
        showInput.value = show;
    };
    const getRemainingChars = computed(() => {
        if (!props.maxChars)
            return;
        if (!value.value)
            return {
                remainingChars: 0,
                limitExceeded: false,
            };
        const currentValue = value.value;
        const limitExceeded = currentValue.length > props.maxChars;
        const remainingChars = currentValue.length;
        if (currentValue.length > props.maxChars) {
            return {
                remainingChars: props.maxChars,
                currentValue: currentValue.length,
                limitExceeded: true,
            };
        }
        return {
            remainingChars,
            currentValue: currentValue.length,
            limitExceeded,
        };
    });
    return {
        errors,
        value,
        field,
        getErrorArgs,
        showInput,
        toggleShowInput,
        getRemainingChars,
        meta
    };
};
