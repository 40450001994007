import { v4 } from "uuid";
import { format } from 'date-fns';
export default function createInfoObject(type, data, context, mode = '') {
    return {
        header: {
            version: '1.0.2',
            id: v4(),
            parentId: '',
            type: 'INFO_OBJECT_DEFAULT',
            status: 'NEW',
            history: [
                {
                    step: '1',
                    timestamp: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
                    unit: v4(),
                    type: 'SERVICE_REQUEST',
                    status: 'NEW'
                }
            ]
        },
        payload: {
            type: type,
            context: context,
            mode: mode,
            data: data
        }
    };
}
