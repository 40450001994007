import { ref } from "vue";
export const useIntersectionObserver = (customCallBack) => {
    const observedElement = ref(null);
    const elementClass = ref("");
    const interOptions = (rootPar, thresholdPar) => {
        return {
            root: rootPar,
            threshold: thresholdPar,
        };
    };
    const interCallback = function (entries) {
        if (entries) {
            const [entry] = entries;
            if (customCallBack) {
                customCallBack(entry);
            }
            else if (observedElement.value && entry.isIntersecting) {
                observedElement.value.classList.add(elementClass.value);
            }
        }
    };
    const observer = new IntersectionObserver(interCallback, interOptions);
    return {
        observedElement,
        elementClass,
        interOptions,
        interCallback,
        observer,
    };
};
