import { sendRequest } from '@/lib/request';
import { serviceTypeNames } from '@/lib/service';
import CliffMarketService from '@/services/cliff-market.service';
import { COURSES_LIST } from '@/utils/constants';
import { defineStore } from 'pinia';
import { useCartStore } from './cart.module';
import { useProductStore } from './product.module';
import { i18n } from '@/i18n';
import { useToastStore } from '../app/toast.module';
import { useUserStore } from './user.module';
import { loadingNames } from '../app/loading.module';
export const useFavoritesStore = defineStore('favoritesModule', {
    state: () => {
        return {
            singleObject: null,
            shortlist: {
                total: 0,
                page: 1,
                itemsPerPage: 10,
                list: [],
                limit: null,
            },
            shortlistItems: {
                total: 0,
                page: 1,
                itemsPerPage: 10,
                list: [],
                limit: null,
            },
            watachlistItems: {
                total: 0,
                page: 1,
                itemsPerPage: 10,
                list: [],
                limit: null,
            },
            purchaseTemplates: {
                total: 0,
                page: 1,
                itemsPerPage: 10,
                list: [],
                limit: null,
            },
            purchaseTemplateItems: {
                total: 0,
                page: 1,
                itemsPerPage: 10,
                list: [],
                limit: null,
            },
            addItems: [],
        };
    },
    actions: {
        async ADD_FAVORITES_ITEM(payload, type, mode = 'add') {
            const response = await sendRequest(type, payload);
            if (mode === 'add') {
                this.shortlistItems.list.unshift(response);
                this.shortlistItems.total += 1;
            }
            else {
                const updatedIdx = this.shortlistItems.list.findIndex((item) => item.shortlistItemUniqueId === payload.shortlistItemUniqueId);
                this.shortlistItems.list.splice(updatedIdx, 1, response);
            }
            return response;
        },
        async ADD_WATCHLIST_ITEM(payload, type, mode = 'add') {
            const response = await sendRequest(type, payload);
            if (mode === 'add') {
                this.watachlistItems.list.unshift(response);
                this.watachlistItems.total += 1;
            }
            else {
                const updatedIdx = this.watachlistItems.list.findIndex((item) => item.shortlistItemUniqueId === payload.shortlistItemUniqueId);
                this.watachlistItems.list.splice(updatedIdx, 1, response);
            }
            return response;
        },
        async ADD_PURCHASE_TEMPLATE_ITEM(payload, type) {
            const response = await sendRequest(type, payload, loadingNames.SHORTLIST_ITEM_CREATE, 'Item added to shortlist');
            if (response) {
                this.purchaseTemplateItems.list.unshift(response);
                this.purchaseTemplateItems.total += 1;
            }
            return response;
        },
        async LIST_ITEM(options) {
            const response = await sendRequest(serviceTypeNames.SHORTLIST_ITEM_LIST, options);
            this.shortlist = response;
            return response;
        },
        async LIST(options, type) {
            const res = await sendRequest(type, options);
            this.shortlist = res;
            return res;
        },
        async LIST_TEMPLATES(options, type) {
            const res = await sendRequest(type, options);
            this.purchaseTemplates = res;
            return res;
        },
        async LIST_TEMPLATE_ITEMS(options, type) {
            const res = await sendRequest(type, options);
            this.addItems = [];
            if (res.list.length) {
                res.list.forEach((item) => {
                    this.addItems.push(item.productUniqueId);
                });
            }
            return res;
        },
        async GET(options, type = 'SHORTLIST_GET') {
            const res = await sendRequest(type, options);
            this.singleObject = res;
            return res;
        },
        async LIST_SHORTLIST_ITEMS(type = 'FAVORITES') {
            const userStore = useUserStore();
            this.shortlistItems.list = [];
            const response = await sendRequest(serviceTypeNames.SHORTLIST_LIST, {
                limit: null,
                filter: {
                    'meta_owner_account.gen_account_unique_id': userStore.profile?.accountUniqueId,
                },
                order: {},
                itemsPerPage: 1,
                page: 1,
            });
            let shortlist = null;
            if (type === 'FAVORITES') {
                shortlist = response.list.find((item) => item.shortlistType === 'SHORTLIST_FAVORITES');
            }
            else if (type === 'WATCHLIST') {
                shortlist = response.list.find((item) => item.shortlistType === 'SHORTLIST_WATCHLIST');
            }
            if (response.list.length && shortlist) {
                const shortlistItems = await CliffMarketService.SHORTLIST_ITEM_LIST({
                    limit: 50,
                    itemsPerPage: 50,
                    filter: {
                        'gen_shortlist.gen_shortlist_unique_id': shortlist.shortlistUniqueId,
                    },
                    order: {},
                    page: 1,
                }, 'SHORTLIST_ITEM_LIST');
                if (type === 'WATCHLIST') {
                    this.watachlistItems = shortlistItems;
                    this.watachlistItems.list = shortlistItems.list
                        .map((item) => {
                        const course = COURSES_LIST.find((course) => item.mediaCollectionItemUniqueId === course.uuid);
                        if (course) {
                            return {
                                ...course,
                                shortlistItemUniqueId: item.shortlistItemUniqueId,
                            };
                        }
                    })
                        .filter((item) => item !== null && item !== undefined);
                }
                else if (type === 'FAVORITES') {
                    const productStore = useProductStore();
                    let productsList = [...productStore.products.list];
                    // if (!productStore.allProducts.list.length) {
                    productsList = await productStore.SET_PRODUCTS('/json/final_v1.0.json', 'ALL', true);
                    // }
                    this.shortlistItems = { ...shortlistItems };
                    this.shortlistItems.list = shortlistItems.list
                        .map((item) => {
                        const prod = productsList.find((prod) => item.productUniqueId === prod.productUniqueId);
                        if (prod) {
                            return {
                                ...prod,
                                shortlistItemUniqueId: item.shortlistItemUniqueId,
                            };
                        }
                        return null;
                    })
                        .filter((item) => item !== null && item !== undefined);
                }
            }
            return response;
        },
        async ADD(payload, type, mode = 'add') {
            const response = await sendRequest(type, payload, undefined);
            if (mode === 'add') {
                this.shortlist.list.unshift(response);
                this.shortlist.total += 1;
            }
            else {
                const updatedIdx = this.shortlist.list.findIndex((item) => item.shortlistUniqueId === payload.shortlistUniqueId);
                this.shortlist.list.splice(updatedIdx, 1, response);
            }
            this.singleObject = response;
            return response;
        },
        async DELETE(payload, type) {
            const response = await sendRequest(type, payload);
            const deletedIdx = this.shortlist.list.findIndex((item) => item.shortlistUniqueId === payload.shortlistUniqueId);
            this.shortlist.list.splice(deletedIdx, 1);
            this.shortlist.total -= 1;
            return response;
        },
        async DELETE_TEMPLATE(payload, type) {
            const response = await sendRequest(type, payload);
            const deletedIdx = this.purchaseTemplates.list.findIndex((item) => item.shortlistUniqueId === payload.shortlistUniqueId);
            this.purchaseTemplates.list.splice(deletedIdx, 1);
            this.purchaseTemplates.total -= 1;
            return response;
        },
        async DELETE_FAVORITE_ITEM(payload, type) {
            const response = await sendRequest(type, payload);
            const deletedIdx = this.shortlistItems.list.findIndex((item) => item.shortlistItemUniqueId === payload.shortlistItemUniqueId);
            this.shortlistItems.list.splice(deletedIdx, 1);
            this.shortlistItems.total -= 1;
            return response;
        },
        async DELETE_WATCHLIST_ITEM(payload, type) {
            const response = await sendRequest(type, payload);
            const deletedIdx = this.watachlistItems.list.findIndex((item) => item.shortlistItemUniqueId === payload.shortlistItemUniqueId);
            this.watachlistItems.list.splice(deletedIdx, 1);
            this.watachlistItems.total -= 1;
            return response;
        },
        async ADD_SHORTLIST_ITEMS_TO_CART(shortlistUniqueId) {
            const toastStore = useToastStore();
            const cartStore = useCartStore();
            const data = {
                search: {},
                filter: {
                    'gen_shortlist.gen_shortlist_unique_id': shortlistUniqueId,
                },
                order: {},
                limit: null,
                itemsPerPage: 50,
                page: 1,
            };
            const response = await sendRequest(serviceTypeNames.SHORTLIST_ITEM_LIST, data);
            if (!response.list.length) {
                return toastStore.danger({
                    title: i18n.t('toasts.danger.title'),
                    message: 'No products in the shortlist',
                });
            }
            const validProducts = response.list.filter((value, index, self) => {
                return (value.productUniqueId &&
                    index === self.findIndex((item) => item.productUniqueId === value.productUniqueId));
            });
            await Promise.all(validProducts.map(async (item, i) => {
                return await cartStore.CART_ITEM_ADD({
                    productUniqueId: item.productUniqueId,
                    cartUniqueId: cartStore.cartUniqueId,
                    quantity: 1,
                }, false);
            }));
            cartStore.CART_OBTAIN({ cartUniqueId: cartStore.cartUniqueId, currency: 'EUR' });
            return response;
        },
        async UPDATE(payload) {
            try {
                const options = {
                    channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
                    ...payload,
                };
                const res = await sendRequest(serviceTypeNames.SHORTLIST_UPDATE, options, serviceTypeNames.SHORTLIST_UPDATE, 'Update successful');
                const updatedItemIdx = this.shortlist.list.findIndex((item) => {
                    return item.shortlistUniqueId === payload.shortlistUniqueId;
                });
                if (updatedItemIdx > -1) {
                    this.shortlist.list.splice(updatedItemIdx, 1, res);
                    this.singleObject = res;
                }
                return res;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async CREATE(payload, type) {
            const response = await sendRequest(type, payload, undefined);
            console.log('response', response);
            return response;
        },
        isItemAdded(productId, shortlistUniqueId) {
            console.log('PRODUCT UNIQUE ID', productId);
            // console.log('shortlist unique id',shortlistUniqueId)
            if (this.purchaseTemplateItems.list.length) {
                const result = this.purchaseTemplateItems.list.find((product) => {
                    console.log('inside product', product);
                    return (product.productUniqueId === productId && product.shortlistUniqueId === shortlistUniqueId);
                });
                console.log(result);
                if (result && result.productId) {
                    return true;
                }
                else {
                    return false;
                }
            }
        },
    },
});
