import { sendRequest } from "@/lib/request";
import { serviceTypeNames } from "@/lib/service";
import { defineStore } from "pinia";
export const useNotificationStore = defineStore("notificationModule", {
    state: () => ({
        // dismissed: false,
        notifications: [],
        currentNotificationIndex: 0,
    }),
    getters: {
        systemNotification(state) {
            return state.notifications.map((notification) => {
                const ntfBody = notification.notificationItems[0]
                    ? JSON.parse(notification.notificationItems[0].notificationItemBody)
                    : undefined;
                return {
                    id: notification.notificationUniqueId,
                    title: notification.notificationDescShort,
                    message: ntfBody && ntfBody.textPlain ? ntfBody.textPlain : undefined,
                };
            });
        },
        currentSystemNotification() {
            return this.systemNotification && this.systemNotification.length
                ? this.systemNotification[this.currentNotificationIndex]
                : null;
        },
        hasNextNotification(state) {
            return (state.currentNotificationIndex + 1 !== state.notifications.length - 1);
        },
        hasPreviousNotification(state) {
            return state.currentNotificationIndex - 1 >= 0;
        },
        showNotification(state) {
            return (state.currentNotificationIndex >= 0 &&
                state.currentNotificationIndex <= state.notifications.length - 1);
        },
        isLastNotification(state) {
            return state.currentNotificationIndex === state.notifications.length - 1;
        },
    },
    actions: {
        // setDismissed(isDimissed = true) {
        //   this.dismissed = isDimissed;
        // },
        changeNotification(index) {
            // changeNotification(type: "INC" | "DEC" = "INC", index?: number) {
            if (index != null)
                return (this.currentNotificationIndex = index);
            this.currentNotificationIndex += 1;
            // if (index != null && index <= this.notifications.length - 1) {
            //   return (this.currentNotificationIndex = index);
            // }
            // if (type === "INC" && this.hasNextNotification)
            //   return (this.currentNotificationIndex += 1);
            // if (this.hasPreviousNotification) {
            //   this.currentNotificationIndex -= 1;
            // }
        },
        async LIST() {
            try {
                const payload = {
                    filter: {
                        "gen_notification_status.gen_status_desc_short": "NOTIFICATION_ACTIVE",
                        "gen_notification_type.gen_type_desc_short": "NOTIFICATION_SYSTEM",
                    },
                    order: {},
                    page: 1,
                    itemsPerPage: 100,
                    limit: null,
                };
                const res = await sendRequest(serviceTypeNames.NOTIFICATION_LIST, payload);
                this.notifications = res.list;
                return res;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async CHANGE_STATUS(options) {
            await sendRequest(serviceTypeNames.NOTIFICATION_UPDATE, options);
            this.changeNotification();
        },
        // async READ_ALL() {
        //   const statusReqs = this.systemNotification.map((ntf: any) =>
        //     this.CHANGE_STATUS(
        //       {
        //         notificationUniqueId: ntf.id,
        //         notificationUserRelStatus: NotificationStatus.Read,
        //       },
        //       false
        //     )
        //   );
        //   await Promise.all(statusReqs);
        //   this.changeNotification(undefined, -1);
        // },
    },
});
