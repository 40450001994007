<template>
   <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.35621 19.1667L0.729126 17.5396L8.26871 10L0.729126 2.46041L2.35621 0.833328L11.5229 10L2.35621 19.1667Z" fill="white"/>
</svg>

</template>

<script setup lang="ts">

</script>

<style scoped>

</style>