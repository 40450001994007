export var AccountType;
(function (AccountType) {
    AccountType["Individual"] = "ACCOUNT_INDIVIDUAL";
    AccountType["Organization"] = "ACCOUNT_ORGANIZATION";
})(AccountType || (AccountType = {}));
export var UserType;
(function (UserType) {
    UserType["Consumer"] = "CONSUMER";
    UserType["Reseller"] = "RESELLER";
    UserType["Distributor"] = "DISTRIBUTOR";
    UserType["Agent"] = "AGENT";
    UserType["Professional"] = "PROFESSIONAL";
    UserType["Salon"] = "SALON";
})(UserType || (UserType = {}));
// export enum ApplicationSubject {
//   Individual = 'CUSTOMER_GROUP_INDIVIDUAL',
//   Business = 'CUSTOMER_GROUP_BUSINESS',
//   Retailer = 'CUSTOMER_GROUP_RETAILER',
//   Distributor = 'CUSTOMER_GROUP_DISTRIBUTOR',
//   Reseller = 'CUSTOMER_GROUP_RESELLER',
// }
export var ApplicationSubject;
(function (ApplicationSubject) {
    ApplicationSubject["Individual"] = "CONSUMER_INDIVIDUAL";
    ApplicationSubject["Business"] = "CONSUMER_BUSINESS";
    ApplicationSubject["Retailer_salon"] = "RETAILER_SALON";
    ApplicationSubject["Retailer_professional"] = "RETAILER_PROFESSIONAL";
    ApplicationSubject["Distributor"] = "DISTRIBUTOR";
    ApplicationSubject["Reseller"] = "RESELLER";
    ApplicationSubject["Sales_agent"] = "SALES_AGENT";
})(ApplicationSubject || (ApplicationSubject = {}));
