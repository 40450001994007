import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
export const usePasswordValidation = () => {
    const { t } = useI18n();
    const rules = computed(() => [
        {
            text: t('pages.register.form.step2.rules.lower-case-check'),
            type: 'lower-case-check',
        },
        {
            text: t('pages.register.form.step2.rules.upper-case-check'),
            type: 'upper-case-check',
        },
        {
            text: t('pages.register.form.step2.rules.number'),
            type: 'number',
        },
        {
            text: t('pages.register.form.step2.rules.character-length-min'),
            type: 'character-length-min',
        },
        {
            text: t('pages.register.form.step2.rules.character-length-max'),
            type: 'character-length-max',
        },
        // {
        //   text: 'Password must contain a special character',
        //   type: 'special-character',
        // },
        {
            text: t('pages.register.form.step2.rules.space'),
            type: 'space',
        },
        {
            text: t('pages.register.form.step2.rules.passwords-match'),
            type: 'passwords-match',
        },
    ]);
    const passwordValidated = (values, index) => {
        const foundRule = rules.value[index];
        // console.log(values.password && values.password.length);
        // console.log(values);
        // let leadingTrailingWhiteSpaceCheck = new RegExp(/^\s+|\s+$/g);
        if (foundRule.type === 'space' && values.password && values.password.length > 0) {
            if (values.password.includes(' ')) {
                return false;
            }
            else {
                return true;
            }
        }
        if (values.password || values.passwordRepeat) {
            const lowerCharacterCheck = new RegExp(/(?=.*[a-z])/);
            const upperCharacterCheck = new RegExp(/(?=.*[A-Z])/);
            const numberCheck = new RegExp(/(?=.*[0-9])/);
            // let specialCharacterCheck = new RegExp(/(?=.*[^A-Za-z0-9])/);
            const MinlengthCheck = new RegExp(/(?=.{8,})/);
            const MaxlengthCheck = new RegExp(/^(?=.{0,50}$)(?!.*.{51})/);
            if (values.password) {
                switch (foundRule.type) {
                    case 'lower-case-check':
                        return lowerCharacterCheck.test(values.password);
                    case 'upper-case-check':
                        return upperCharacterCheck.test(values.password);
                    case 'number':
                        return numberCheck.test(values.password);
                    case 'character-length-min':
                        return MinlengthCheck.test(values.password);
                    case 'character-length-max':
                        console.log(MaxlengthCheck.test(values.password));
                        return MaxlengthCheck.test(values.password);
                    // case 'special-character':
                    //   return specialCharacterCheck.test(values.password);
                }
            }
            if (values.passwordRepeat && values.password && foundRule.type === 'passwords-match') {
                if (values.password !== values.passwordRepeat) {
                    return false;
                }
                else {
                    return true;
                }
            }
        }
    };
    return {
        rules,
        passwordValidated
    };
};
