import { useReCaptcha } from "vue-recaptcha-v3";
import { ref } from "vue";
import cliffMarketService from "@/services/cliff-market.service";
import { useToastStore } from "@/store/modules/app/toast.module";
import { useI18n } from "vue-i18n";
export const useGoogleRecaptcha = () => {
    const { t } = useI18n({ useScope: "global" });
    const isLoading = ref(false);
    const googleRecaptcha = useReCaptcha();
    const toastStore = useToastStore();
    const recaptcha = async (action) => {
        isLoading.value = true;
        await googleRecaptcha?.recaptchaLoaded();
        try {
            const token = await googleRecaptcha?.executeRecaptcha(action);
            if (!token) {
                toastStore.danger({
                    title: t("toasts.warning.titles.google-recaptcha-failed"),
                    message: t("toasts.warning.messages.google-recaptcha-failed"),
                    duration: -1,
                });
                return 0;
            }
            const response = await cliffMarketService.CAPTCHA_VERIFY({
                captchaType: "GOOGLE_RECAPTCHA_V3",
                captchaData: { token: token },
            });
            if (response.score <= 0.3) {
                toastStore.danger({
                    title: t("toasts.warning.titles.google-recaptcha-failed"),
                    message: t("toasts.warning.messages.google-recaptcha-failed"),
                    duration: -1,
                });
                throw new Error('Failed');
            }
            return response.score;
        }
        catch (error) {
            toastStore.danger({
                title: t("toasts.warning.titles.google-recaptcha-failed"),
                message: t("toasts.warning.messages.google-recaptcha-failed"),
                duration: -1,
            });
            return Promise.reject(error);
        }
        finally {
            isLoading.value = false;
        }
    };
    return {
        recaptcha,
        isLoading,
    };
};
